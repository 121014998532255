export const WORDS = [
  'ܚܛܝܬܐ',
  'ܥܒܘܝܐ',
  'ܫܘܓܠܐ',
  'ܡܫܪܩܥ',
  'ܡܘܚܒܐ',
  'ܫܪܒܬܐ',
  'ܩܢܝܛܐ',
  'ܒܛܡܬܐ',
  'ܒܚܪܬܐ',
  'ܡܦܬܓܡ',
  'ܕܪܘܙܐ',
  'ܡܣܓܕܐ',
  'ܚܫܠܬܐ',
  'ܓܘܒܪܐ',
  'ܨܪܒܝܐ',
  'ܐܘܪܚܐ',
  'ܩܪܝܒܐ',
  'ܚܫܘܫܐ',
  'ܓܪܘܪܐ',
  'ܓܘܝܒܐ',
  'ܝܕܝܕܐ',
  'ܬܘܡܢܐ',
  'ܐܚܘܬܐ',
  'ܦܘܢܕܐ',
  'ܒܗܐܝܐ',
  'ܣܒܠܬܐ',
  'ܥܡܘܪܐ',
  'ܛܘܦܣܐ',
  'ܙܕܝܩܐ',
  'ܡܫܬܐܣ',
  'ܛܡܘܡܐ',
  'ܩܡܘܙܐ',
  'ܚܟܡܬܐ',
  'ܓܢܒܪܐ',
  'ܡܒܪܒܙ',
  'ܐܪܢܒܐ',
  'ܐܟܝܬܘ',
  'ܬܘܩܦܐ',
  'ܦܠܓܝܐ',
  'ܟܪܘܟܐ',
  'ܡܪܐܝܠ',
  'ܓܘܢܝܐ',
  'ܡܬܫܥܐ',
  'ܢܘܗܪܐ',
  'ܒܘܪܨܐ',
  'ܚܙܘܩܐ',
  'ܩܠܝܡܐ',
  'ܡܟܠܝܐ',
  'ܡܣܐܬܐ',
  'ܥܠܝܠܐ',
  'ܟܣܘܝܐ',
  'ܡܚܪܒܫ',
  'ܓܪܘܡܐ',
  'ܝܪܩܢܐ',
  'ܡܫܪܝܐ',
  'ܪܘܡܬܐ',
  'ܫܠܝܨܐ',
  'ܐܬܘܬܐ',
  'ܡܦܩܬܐ',
  'ܟܠܝܦܐ',
  'ܪܘܒܢܐ',
  'ܛܘܝܠܐ',
  'ܦܣܬܩܐ',
  'ܚܘܪܡܐ',
  'ܚܫܝܫܐ',
  'ܚܟܝܡܐ',
  'ܡܬܦܬܦ',
  'ܐܝܠܢܐ',
  'ܚܘܕܬܐ',
  'ܓܠܘܠܐ',
  'ܚܘܫܬܐ',
  'ܟܘܪܦܐ',
  'ܡܘܛܝܐ',
  'ܢܩܒܬܐ',
  'ܩܘܦܠܐ',
  'ܗܓܝܢܐ',
  'ܒܢܘܢܐ',
  'ܢܗܪܝܢ',
  'ܒܣܝܪܐ',
  'ܬܫܪܝܢ',
  'ܥܠܝܡܐ',
  'ܬܪܝܢܐ',
  'ܫܪܝܬܐ',
  'ܟܢܓܪܐ',
  'ܒܪܝܟܐ',
  'ܕܘܓܠܐ',
  'ܡܟܠܟܠ',
  'ܡܬܩܬܩ',
  'ܟܦܦܬܐ',
  'ܫܪܘܬܐ',
  'ܐܪܝܬܐ',
  'ܒܛܝܠܐ',
  'ܬܪܘܕܐ',
  'ܙܪܘܩܐ',
  'ܚܠܝܨܐ',
  'ܐܢܛܘܢ',
  'ܦܘܪܢܐ',
  'ܨܘܡܟܐ',
  'ܥܝܕܝܐ',
  'ܟܠܢܝܐ',
  'ܒܨܦܪܐ',
  'ܒܪܒܒܐ',
  'ܪܘܓܙܐ',
  'ܡܘܚܡܕ',
  'ܡܘܪܝܣ',
  'ܐܠܨܝܐ',
  'ܡܩܛܩܛ',
  'ܠܘܘܝܐ',
  'ܦܚܠܬܐ',
  'ܘܠܝܬܐ',
  'ܬܓܪܝܐ',
  'ܡܚܪܡܛ',
  'ܩܝܢܝܐ',
  'ܚܫܚܝܐ',
  'ܥܡܡܝܐ',
  'ܣܢܣܪܐ',
  'ܓܡܝܟܐ',
  'ܩܫܝܫܐ',
  'ܢܦܝܩܐ',
  'ܒܝܥܬܐ',
  'ܐܚܘܕܐ',
  'ܚܘܟܟܐ',
  'ܡܚܕܪܐ',
  'ܡܬܪܥܣ',
  'ܚܪܝܦܐ',
  'ܝܘܚܢܢ',
  'ܡܛܪܡܫ',
  'ܣܘܗܕܐ',
  'ܐܚܪܝܐ',
  'ܬܢܝܢܐ',
  'ܡܐܬܝܢ',
  'ܒܠܘܛܐ',
  'ܠܝܛܪܐ',
  'ܡܠܦܢܐ',
  'ܒܣܝܡܐ',
  'ܡܒܠܩܢ',
  'ܘܪܝܕܐ',
  'ܫܘܠܦܐ',
  'ܐܒܝܫܐ',
  'ܡܫܚܬܢ',
  'ܚܡܘܨܐ',
  'ܣܡܪܢܐ',
  'ܨܘܒܝܐ',
  'ܡܨܢܥܐ',
  'ܡܫܝܕܢ',
  'ܡܦܪܦܠ',
  'ܠܥܘܣܐ',
  'ܡܫܥܡܪ',
  'ܫܪܝܪܐ',
  'ܝܓܝܣܐ',
  'ܩܙܒܝܢ',
  'ܠܣܘܛܘ',
  'ܕܥܝܟܐ',
  'ܝܪܘܩܐ',
  'ܛܫܗܝܐ',
  'ܣܦܣܠܐ',
  'ܦܫܝܩܐ',
  'ܦܣܘܩܐ',
  'ܚܠܘܨܐ',
  'ܚܙܘܪܐ',
  'ܦܛܝܚܐ',
  'ܓܘܠܥܐ',
  'ܫܦܬܝܐ',
  'ܥܩܪܒܐ',
  'ܓܠܝܕܐ',
  'ܝܡܡܝܐ',
  'ܪܘܣܝܐ',
  'ܝܨܝܦܐ',
  'ܫܥܘܬܐ',
  'ܚܠܘܝܐ',
  'ܡܕܠܝܐ',
  'ܟܢܘܢܐ',
  'ܐܝܡܡܐ',
  'ܠܗܝܒܐ',
  'ܣܡܠܬܐ',
  'ܩܘܪܒܐ',
  'ܢܫܝܢܐ',
  'ܒܬܪܟܢ',
  'ܡܢܝܢܐ',
  'ܒܪܝܬܐ',
  'ܬܐܘܡܐ',
  'ܡܦܪܓܐ',
  'ܒܘܫܠܐ',
  'ܡܘܣܝܐ',
  'ܢܩܝܕܐ',
  'ܡܥܡܪܐ',
  'ܫܠܝܛܐ',
  'ܐܟܪܬܐ',
  'ܦܝܘܚܐ',
  'ܒܢܙܝܢ',
  'ܚܢܘܬܐ',
  'ܢܛܘܪܐ',
  'ܣܝܛܠܐ',
  'ܠܘܣܬܐ',
  'ܒܝܪܘܬ',
  'ܡܚܡܠܐ',
  'ܦܝܠܡܐ',
  'ܒܪܩܬܐ',
  'ܝܪܘܬܐ',
  'ܡܣܚܝܐ',
  'ܝܘܐܒܐ',
  'ܩܘܡܬܐ',
  'ܓܒܝܢܐ',
  'ܣܘܕܪܐ',
  'ܡܫܝܢܐ',
  'ܡܕܫܕܫ',
  'ܗܕܝܐܐ',
  'ܚܡܝܢܐ',
  'ܙܘܪܢܐ',
  'ܣܡܝܟܐ',
  'ܒܪܫܝܬ',
  'ܚܡܘܕܐ',
  'ܡܘܓܘܠ',
  'ܓܘܪܒܐ',
  'ܙܡܒܝܐ',
  'ܩܦܘܚܐ',
  'ܪܗܘܡܐ',
  'ܩܢܝܬܐ',
  'ܡܣܘܟܢ',
  'ܢܣܝܪܐ',
  'ܒܘܩܬܐ',
  'ܡܪܝܥܐ',
  'ܙܥܘܩܐ',
  'ܡܚܫܒܢ',
  'ܣܝܘܡܐ',
  'ܡܥܘܩܐ',
  'ܟܘܟܒܐ',
  'ܙܡܪܬܐ',
  'ܣܡܠܝܐ',
  'ܚܙܘܦܐ',
  'ܣܦܝܪܐ',
  'ܟܠܒܬܐ',
  'ܚܘܫܒܐ',
  'ܡܗܡܙܡ',
  'ܟܟܡܟܐ',
  'ܒܘܡܒܐ',
  'ܙܪܝܙܐ',
  'ܫܡܝܪܡ',
  'ܛܒܝܥܐ',
  'ܒܢܝܢܐ',
  'ܐܦܝܬܐ',
  'ܚܬܝܬܐ',
  'ܒܪܛܠܐ',
  'ܐܪܟܝܐ',
  'ܟܢܝܫܐ',
  'ܣܘܠܩܐ',
  'ܐܘܝܢܐ',
  'ܢܟܘܦܐ',
  'ܡܫܪܒܛ',
  'ܚܙܝܪܢ',
  'ܗܘܢܢܐ',
  'ܬܚܬܝܐ',
  'ܠܒܢܬܐ',
  'ܣܥܪܬܐ',
  'ܡܙܝܕܐ',
  'ܡܫܡܗܐ',
  'ܟܬܒܬܐ',
  'ܟܘܬܠܐ',
  'ܬܫܝܠܐ',
  'ܕܣܢܝܐ',
  'ܡܪܝܪܐ',
  'ܙܝܘܥܐ',
  'ܣܒܝܗܐ',
  'ܡܪܟܒܐ',
  'ܝܘܢܝܐ',
  'ܡܫܥܠܐ',
  'ܒܢܝܩܐ',
  'ܝܘܕܥܐ',
  'ܣܟܝܢܐ',
  'ܙܘܢܪܐ',
  'ܐܢܬܘܢ',
  'ܓܠܝܢܐ',
  'ܙܟܘܬܐ',
  'ܡܫܡܫܢ',
  'ܪܩܝܩܐ',
  'ܪܘܒܥܐ',
  'ܚܟܘܟܐ',
  'ܡܒܪܣܡ',
  'ܩܕܝܠܐ',
  'ܗܪܘܡܐ',
  'ܬܘܩܢܐ',
  'ܓܕܝܫܐ',
  'ܣܡܘܝܐ',
  'ܫܐܕܢܐ',
  'ܓܘܢܚܐ',
  'ܢܥܝܡܐ',
  'ܝܪܝܟܐ',
  'ܣܘܝܟܐ',
  'ܬܪܝܨܐ',
  'ܫܒܘܛܐ',
  'ܛܘܝܣܐ',
  'ܚܘܠܦܐ',
  'ܬܝܡܢܐ',
  'ܡܪܫܡܐ',
  'ܐܙܠܬܐ',
  'ܗܘܝܐܐ',
  'ܬܢܘܪܐ',
  'ܢܙܠܬܐ',
  'ܒܕܘܟܬ',
  'ܒܪܡܫܐ',
  'ܩܢܘܢܐ',
  'ܐܪܘܢܐ',
  'ܡܣܡܣܩ',
  'ܒܘܪܟܐ',
  'ܐܘܢܝܠ',
  'ܡܢܕܝܐ',
  'ܡܣܩܠܩ',
  'ܬܪܡܠܐ',
  'ܡܟܪܦܫ',
  'ܩܠܝܬܐ',
  'ܫܘܪܬܐ',
  'ܡܕܝܢܐ',
  'ܚܒܘܛܐ',
  'ܡܨܚܨܐ',
  'ܡܩܢܘܢ',
  'ܡܥܪܘܨ',
  'ܚܫܘܠܐ',
  'ܛܘܪܝܐ',
  'ܗܡܝܪܐ',
  'ܠܒܬܪܐ',
  'ܥܢܝܢܐ',
  'ܩܪܝܚܐ',
  'ܦܬܝܚܐ',
  'ܚܙܘܢܐ',
  'ܡܠܩܛܐ',
  'ܫܝܪܝܐ',
  'ܟܪܝܒܐ',
  'ܥܪܦܠܐ',
  'ܩܪܘܪܐ',
  'ܟܪܡܠܫ',
  'ܐܠܦܝܐ',
  'ܥܣܪܝܐ',
  'ܩܢܫܬܐ',
  'ܥܠܘܝܐ',
  'ܡܚܠܦܐ',
  'ܛܐܠܢܐ',
  'ܓܡܒܝܐ',
  'ܓܘܪܓܐ',
  'ܙܡܝܢܐ',
  'ܨܠܝܒܐ',
  'ܡܛܚܢܐ',
  'ܦܘܫܝܐ',
  'ܡܓܢܓܙ',
  'ܦܪܒܠܐ',
  'ܟܫܝܪܐ',
  'ܠܘܩܛܐ',
  'ܟܝܢܝܐ',
  'ܓܫܘܫܐ',
  'ܐܝܠܘܠ',
  'ܣܘܪܝܐ',
  'ܨܘܪܬܐ',
  'ܐܝܡܝܠ',
  'ܓܡܠܬܐ',
  'ܡܝܠܛܐ',
  'ܟܦܝܢܐ',
  'ܬܘܠܬܐ',
  'ܡܚܡܚܡ',
  'ܡܝܬܪܐ',
  'ܡܬܦܬܫ',
  'ܡܣܪܥܦ',
  'ܩܝܘܡܐ',
  'ܣܒܝܪܐ',
  'ܛܘܟܣܐ',
  'ܟܢܝܙܐ',
  'ܫܒܒܝܐ',
  'ܫܚܘܡܐ',
  'ܫܡܚܢܐ',
  'ܘܪܘܕܐ',
  'ܨܝܢܝܐ',
  'ܬܦܢܟܐ',
  'ܬܪܘܨܐ',
  'ܚܕܢܝܐ',
  'ܫܩܝܬܐ',
  'ܡܣܪܛܐ',
  'ܘܠܝܝܐ',
  'ܡܘܥܕܐ',
  'ܡܓܠܓܠ',
  'ܣܘܓܠܐ',
  'ܒܢܦܫܐ',
  'ܦܘܬܚܐ',
  'ܡܣܡܣܡ',
  'ܢܝܢܘܐ',
  'ܩܘܪܛܐ',
  'ܪܓܘܫܐ',
  'ܙܘܗܪܐ',
  'ܦܬܚܬܐ',
  'ܫܘܒܥܐ',
  'ܩܘܛܒܐ',
  'ܒܣܒܪܐ',
  'ܟܪܟܢܐ',
  'ܢܒܓܬܐ',
  'ܩܘܠܥܐ',
  'ܟܘܬܪܐ',
  'ܟܪܝܗܐ',
  'ܡܨܪܝܐ',
  'ܦܫܝܛܐ',
  'ܡܫܘܚܪ',
  'ܐܠܩܘܫ',
  'ܣܘܦܝܐ',
  'ܚܕܪܘܢ',
  'ܫܘܚܕܐ',
  'ܟܘܪܝܐ',
  'ܓܘܒܬܐ',
  'ܫܪܘܝܐ',
  'ܡܓܢܝܐ',
  'ܫܒܘܥܐ',
  'ܥܣܪܬܐ',
  'ܠܩܠܩܐ',
  'ܢܨܝܚܐ',
  'ܚܘܒܬܐ',
  'ܪܥܝܢܐ',
  'ܡܪܪܬܐ',
  'ܡܩܪܢܐ',
  'ܚܝܘܬܐ',
  'ܬܘܫܥܐ',
  'ܦܬܓܡܐ',
  'ܥܬܝܩܐ',
  'ܘܙܝܪܐ',
  'ܡܚܙܕܓ',
  'ܦܬܠܓܢ',
  'ܣܘܟܢܐ',
  'ܨܒܝܢܐ',
  'ܝܘܗܒܐ',
  'ܓܘܢܢܐ',
  'ܕܡܝܟܐ',
  'ܣܡܘܩܐ',
  'ܝܩܘܪܐ',
  'ܪܘܢܕܐ',
  'ܒܠܝܓܐ',
  'ܡܒܕܒܕ',
  'ܕܘܟܬܐ',
  'ܡܝܛܪܐ',
  'ܕܩܝܩܐ',
  'ܡܠܝܘܢ',
  'ܓܠܝܙܐ',
  'ܫܘܪܝܐ',
  'ܕܡܝܢܐ',
  'ܠܒܨܝܪ',
  'ܚܘܨܪܐ',
  'ܕܘܟܠܐ',
  'ܦܪܝܫܐ',
  'ܐܐܪܝܐ',
  'ܦܘܟܪܐ',
  'ܐܪܒܥܐ',
  'ܡܣܙܓܪ',
  'ܙܩܝܦܐ',
  'ܥܙܝܙܐ',
  'ܡܗܘܢܢ',
  'ܓܘܒܝܐ',
  'ܐܘܪܙܐ',
  'ܡܙܥܙܥ',
  'ܡܥܪܦܠ',
  'ܫܟܝܪܐ',
  'ܫܘܐܠܐ',
  'ܡܘܠܕܐ',
  'ܚܘܪܕܐ',
  'ܓܘܢܩܐ',
  'ܡܨܪܦܚ',
  'ܐܣܘܪܐ',
  'ܐܨܛܚܢ',
  'ܠܬܒܝܐ',
  'ܚܘܝܨܐ',
  'ܒܝܛܢܡ',
  'ܗܠܝܘܢ',
  'ܛܥܝܫܐ',
  'ܡܚܕܝܐ',
  'ܟܘܢܝܐ',
  'ܚܠܘܠܐ',
  'ܪܩܝܥܐ',
  'ܥܠܐܣܣ',
  'ܡܥܙܬܐ',
  'ܬܘܬܪܐ',
  'ܩܛܝܢܐ',
  'ܩܛܥܢܐ',
  'ܣܘܣܬܐ',
  'ܗܝܟܠܐ',
  'ܒܘܥܕܐ',
  'ܓܨܘܨܐ',
  'ܒܪܢܫܐ',
  'ܐܚܕܕܐ',
  'ܡܕܡܕܡ',
  'ܟܘܫܝܐ',
  'ܡܦܪܡܐ',
  'ܦܥܝܠܐ',
  'ܛܠܘܡܐ',
  'ܓܙܐܝܪ',
  'ܓܒܪܬܐ',
  'ܡܕܢܚܐ',
  'ܪܒܝܥܐ',
  'ܫܓܝܡܐ',
  'ܣܘܦܪܐ',
  'ܦܢܕܩܐ',
  'ܡܫܠܗܒ',
  'ܬܟܝܠܐ',
  'ܡܥܪܒܐ',
  'ܙܥܘܪܐ',
  'ܢܨܪܝܐ',
  'ܣܪܛܢܐ',
  'ܢܝܠܘܣ',
  'ܙܘܝܬܐ',
  'ܡܙܪܙܛ',
  'ܫܡܘܢܝ',
  'ܟܘܐܠܐ',
  'ܡܫܪܬܚ',
  'ܡܒܘܥܐ',
  'ܐܣܝܪܐ',
  'ܡܪܦܪܦ',
  'ܦܪܣܝܐ',
  'ܐܫܛܪܐ',
  'ܓܒܪܝܠ',
  'ܡܕܪܝܕ',
  'ܥܪܙܠܐ',
  'ܡܦܪܦܥ',
  'ܡܚܕܬܐ',
  'ܡܫܓܬܐ',
  'ܫܘܝܫܐ',
  'ܢܘܪܢܐ',
  'ܡܬܚܙܐ',
  'ܩܕܡܝܐ',
  'ܡܓܪܓܫ',
  'ܐܫܬܝܢ',
  'ܕܘܟܝܐ',
  'ܡܨܥܝܐ',
  'ܫܦܝܪܐ',
  'ܩܗܝܪܐ',
  'ܒܪܩܝܐ',
  'ܡܣܪܩܐ',
  'ܚܡܫܝܢ',
  'ܣܝܢܡܐ',
  'ܫܐܪܬܐ',
  'ܬܡܝܡܐ',
  'ܦܠܦܠܐ',
  'ܡܦܩܢܐ',
  'ܡܘܒܠܐ',
  'ܚܙܝܬܐ',
  'ܥܘܝܡܐ',
  'ܪܘܚܩܐ',
  'ܢܘܨܦܐ',
  'ܕܘܢܝܐ',
  'ܥܢܟܒܐ',
  'ܚܘܡܠܐ',
  'ܨܘܠܬܐ',
  'ܐܬܘܢܐ',
  'ܛܘܝܒܐ',
  'ܐܛܘܡܐ',
  'ܡܚܝܠܐ',
  'ܒܒܘܢܐ',
  'ܒܚܪܝܢ',
  'ܫܪܫܝܐ',
  'ܡܚܕܕܐ',
  'ܪܝܫܝܐ',
  'ܒܚܝܠܐ',
  'ܢܡܘܣܐ',
  'ܝܬܘܡܐ',
  'ܡܒܨܒܨ',
  'ܗܠܡܘܢ',
  'ܥܘܡܪܐ',
  'ܡܫܥܒܕ',
  'ܬܡܢܝܢ',
  'ܙܠܝܡܐ',
  'ܢܣܘܟܐ',
  'ܗܝܪܬܐ',
  'ܦܛܪܘܣ',
  'ܝܬܒܬܐ',
  'ܟܡܝܥܐ',
  'ܗܢܝܐܐ',
  'ܛܝܣܬܐ',
  'ܡܫܛܚܐ',
  'ܡܫܬܝܐ',
  'ܚܒܝܒܐ',
  'ܡܬܟܬܒ',
  'ܡܕܠܩܒ',
  'ܥܒܝܕܐ',
  'ܡܩܘܡܐ',
  'ܡܘܕܟܐ',
  'ܥܠܘܠܐ',
  'ܚܡܝܡܐ',
  'ܟܪܣܢܐ',
  'ܝܣܡܝܢ',
  'ܡܫܘܕܥ',
  'ܡܩܢܛܪ',
  'ܒܬܫܒܥ',
  'ܕܘܝܩܐ',
  'ܐܣܝܬܐ',
  'ܡܨܢܨܠ',
  'ܩܕܝܡܐ',
  'ܛܒܢܓܐ',
  'ܚܠܝܦܐ',
  'ܡܬܢܬܢ',
  'ܫܠܝܡܐ',
  'ܫܬܝܬܐ',
  'ܐܠܦܝܢ',
  'ܒܝܕܝܘ',
  'ܝܘܦܩܐ',
  'ܐܕܝܘܡ',
  'ܫܛܝܚܐ',
  'ܟܪܦܬܐ',
  'ܓܘܝܢܐ',
  'ܡܣܡܟܐ',
  'ܕܪܓܬܐ',
  'ܐܬܝܬܐ',
  'ܪܕܘܕܐ',
  'ܗܡܙܡܢ',
  'ܠܚܡܝܐ',
  'ܣܘܕܝܐ',
  'ܚܫܘܟܐ',
  'ܟܕܝܒܐ',
  'ܟܘܢܓܘ',
  'ܢܪܒܝܓ',
  'ܬܫܟܝܐ',
  'ܡܫܡܠܐ',
  'ܥܘܣܪܐ',
  'ܓܘܫܡܐ',
  'ܩܘܦܣܐ',
  'ܦܝܫܢܐ',
  'ܡܘܬܢܐ',
  'ܚܒܝܨܐ',
  'ܣܘܟܠܐ',
  'ܪܓܝܙܐ',
  'ܢܬܝܓܐ',
  'ܒܠܘܢܐ',
  'ܗܘܦܟܐ',
  'ܕܒܘܫܐ',
  'ܐܢܬܝܢ',
  'ܦܘܠܘܣ',
  'ܚܙܘܝܐ',
  'ܛܒܝܒܐ',
  'ܫܒܛܪܐ',
  'ܡܬܦܢܟ',
  'ܐܚܘܢܐ',
  'ܪܛܝܒܐ',
  'ܣܘܪܦܐ',
  'ܪܚܘܩܐ',
  'ܡܬܪܓܡ',
  'ܓܘܐܝܬ',
  'ܡܟܪܡܟ',
  'ܣܘܟܪܐ',
  'ܡܬܩܢܐ',
  'ܡܠܘܫܐ',
  'ܦܠܥܢܐ',
  'ܙܪܝܦܐ',
  'ܓܪܡܘܐ',
  'ܣܘܪܩܐ',
  'ܡܟܙܟܙ',
  'ܡܫܘܬܒ',
  'ܡܫܘܬܦ',
  'ܡܟܩܟܩ',
  'ܚܕܥܣܪ',
  'ܕܥܬܝܕ',
  'ܥܘܩܢܐ',
  'ܒܡܬܚܐ',
  'ܬܠܝܠܐ',
  'ܣܢܝܩܐ',
  'ܣܦܝܩܐ',
  'ܘܫܪܟܐ',
  'ܬܢܝܬܐ',
  'ܡܓܪܓܡ',
  'ܡܛܠܬܐ',
  'ܕܒܫܬܐ',
  'ܟܒܝܪܐ',
  'ܡܬܫܘܫ',
  'ܡܣܚܒܪ',
  'ܩܘܒܠܐ',
  'ܚܘܪܝܐ',
  'ܥܠܥܠܐ',
  'ܐܘܦܩܐ',
  'ܚܫܘܒܐ',
  'ܟܟܝܚܐ',
  'ܡܬܠܬܐ',
  'ܩܘܬܝܐ',
  'ܣܓܝܐܐ',
  'ܥܘܪܠܐ',
  'ܡܫܩܫܩ',
  'ܚܘܓܬܐ',
  'ܚܘܪܪܐ',
  'ܡܐܦܝܐ',
  'ܥܪܒܝܐ',
  'ܡܢܣܝܐ',
  'ܫܒܥܝܢ',
  'ܐܫܝܬܐ',
  'ܝܠܘܦܐ',
  'ܟܘܫܦܐ',
  'ܡܬܩܠܐ',
  'ܡܬܝܢܐ',
  'ܡܒܠܒܠ',
  'ܐܕܫܢܬ',
  'ܨܒܥܬܐ',
  'ܩܛܘܢܐ',
  'ܦܠܝܛܐ',
  'ܫܘܝܨܐ',
  'ܡܐܟܢܐ',
  'ܫܚܘܠܐ',
  'ܫܚܝܡܐ',
  'ܫܢܝܙܐ',
  'ܡܟܝܪܐ',
  'ܦܪܠܡܢ',
  'ܫܒܘܪܐ',
  'ܫܚܝܢܐ',
  'ܢܘܓܗܐ',
  'ܨܘܢܡܝ',
  'ܦܪܫܬܐ',
  'ܡܥܠܢܐ',
  'ܡܘܢܚܐ',
  'ܒܪܙܝܠ',
  'ܪܓܘܠܐ',
  'ܡܠܘܚܐ',
  'ܐܝܠܝܢ',
  'ܝܒܝܫܐ',
  'ܩܛܘܡܐ',
  'ܡܚܦܪܦ',
  'ܦܠܢܛܐ',
  'ܡܨܪܝܢ',
  'ܡܠܝܠܐ',
  'ܟܬܝܬܐ',
  'ܟܘܠܒܐ',
  'ܡܪܬܪܬ',
  'ܡܙܘܓܐ',
  'ܛܝܠܬܐ',
  'ܠܚܝܦܐ',
  'ܓܪܒܝܐ',
  'ܥܪܝܨܐ',
  'ܝܠܕܬܐ',
  'ܫܬܩܕܝ',
  'ܩܠܘܠܐ',
  'ܟܝܣܬܐ',
  'ܦܝܬܣܐ',
  'ܡܒܠܕܪ',
  'ܟܡܘܕܐ',
  'ܡܥܠܬܢ',
  'ܟܒܘܫܐ',
  'ܡܦܬܚܐ',
  'ܚܣܝܢܐ',
  'ܪܗܝܒܐ',
  'ܐܡܝܢܐ',
  'ܡܣܩܒܠ',
  'ܒܪܒܪܐ',
  'ܝܠܚܬܐ',
  'ܥܡܘܩܐ',
  'ܝܠܝܕܐ',
  'ܝܘܪܟܐ',
  'ܡܫܚܛܐ',
  'ܬܡܢܝܐ',
  'ܪܬܝܚܐ',
  'ܝܬܝܪܐ',
  'ܡܓܪܒܢ',
  'ܝܘܠܝܐ',
  'ܦܢܓܢܐ',
  'ܝܡܝܢܐ',
  'ܐܬܝܢܐ',
  'ܦܩܘܚܐ',
  'ܢܘܐܝܠ',
  'ܡܠܝܪܕ',
  'ܡܩܠܝܐ',
  'ܣܩܘܡܐ',
  'ܩܘܕܡܐ',
  'ܬܪܥܣܪ',
  'ܚܘܡܫܐ',
  'ܩܘܪܨܐ',
  'ܒܗܘܪܐ',
  'ܫܟܝܚܐ',
  'ܡܙܡܙܡ',
  'ܫܘܚܬܐ',
  'ܣܚܝܢܐ',
  'ܦܝܠܣܐ',
  'ܕܘܟܢܐ',
  'ܐܡܘܪܐ',
  'ܬܪܨܢܐ',
  'ܩܒܘܬܐ',
  'ܫܢܝܢܐ',
  'ܚܘܠܢܐ',
  'ܡܣܪܗܒ',
  'ܥܒܪܝܐ',
  'ܣܘܦܢܐ',
  'ܨܒܘܬܐ',
  'ܡܕܫܡܢ',
  'ܐܝܣܚܩ',
  'ܬܘܓܓܐ',
  'ܐܘܫܦܐ',
  'ܟܘܬܩܐ',
  'ܡܡܠܠܐ',
  'ܡܛܪܛܡ',
  'ܒܘܩܪܐ',
  'ܥܬܝܕܐ',
  'ܐܨܠܝܐ',
  'ܢܘܩܙܐ',
  'ܓܘܠܦܐ',
  'ܡܗܝܪܐ',
  'ܬܫܥܝܢ',
  'ܐܚܝܢܐ',
  'ܢܥܡܬܐ',
  'ܠܘܥܬܐ',
  'ܚܠܝܡܐ',
  'ܚܕܬܬܐ',
  'ܚܠܩܬܐ',
  'ܒܠܚܘܕ',
  'ܐܢܫܝܐ',
  'ܫܘܝܬܐ',
  'ܢܘܣܟܐ',
  'ܒܩܠܘܐ',
  'ܐܝܕܡܐ',
  'ܣܢܐܬܐ',
  'ܢܕܝܕܐ',
  'ܚܘܕܪܐ',
  'ܫܩܠܒܐ',
  'ܛܘܦܪܐ',
  'ܙܒܘܢܐ',
  'ܫܦܘܠܐ',
  'ܟܗܪܒܐ',
  'ܐܫܟܦܐ',
  'ܡܩܪܡܟ',
  'ܝܠܦܘܟ',
  'ܡܒܙܒܙ',
  'ܐܟܘܡܐ',
  'ܡܕܝܪܐ',
  'ܒܒܘܬܐ',
  'ܡܝܩܪܐ',
  'ܣܛܪܝܐ',
  'ܡܫܚܠܦ',
  'ܡܥܝܢܐ',
  'ܦܝܙܝܐ',
  'ܡܦܪܦܫ',
  'ܡܒܢܝܐ',
  'ܥܣܩܬܐ',
  'ܫܟܪܬܐ',
  'ܕܘܪܪܐ',
  'ܡܥܠܬܐ',
  'ܪܕܝܬܐ',
  'ܨܪܘܚܐ',
  'ܐܚܪܢܐ',
  'ܛܠܦܚܐ',
  'ܠܩܕܡܐ',
  'ܫܓܘܡܐ',
  'ܡܫܪܫܪ',
  'ܡܩܪܩܪ',
  'ܒܠܓܝܐ',
  'ܐܓܪܬܐ',
  'ܒܫܝܠܐ',
  'ܡܘܙܘܙ',
  'ܬܪܬܝܢ',
  'ܕܪܥܢܐ',
  'ܗܢܝܢܐ',
  'ܟܬܝܒܐ',
  'ܡܟܠܒܢ',
  'ܚܕܟܡܐ',
  'ܪܨܝܦܐ',
  'ܟܝܘܠܐ',
  'ܛܥܝܡܐ',
  'ܨܘܕܪܐ',
  'ܪܡܘܙܐ',
  'ܥܬܝܪܐ',
  'ܬܘܬܢܐ',
  'ܥܘܕܠܐ',
  'ܥܣܪܝܢ',
  'ܨܘܒܥܐ',
  'ܝܩܝܕܐ',
  'ܒܗܦܟܐ',
  'ܥܡܘܛܐ',
  'ܕܘܪܬܐ',
  'ܡܗܝܡܢ',
  'ܙܓܝܪܐ',
  'ܩܘܪܐܢ',
  'ܚܘܪܓܐ',
  'ܟܘܚܬܐ',
  'ܛܦܝܬܐ',
  'ܦܬܘܪܐ',
  'ܫܪܘܩܐ',
  'ܡܛܥܡܐ',
  'ܡܒܩܠܐ',
  'ܢܝܙܟܐ',
  'ܥܪܒܢܐ',
  'ܛܝܘܣܐ',
  'ܡܥܒܪܐ',
  'ܡܟܫܟܫ',
  'ܡܢܘܢܐ',
  'ܫܒܥܣܪ',
  'ܫܘܬܬܐ',
  'ܙܝܘܢܐ',
  'ܪܡܘܢܐ',
  'ܟܠܫܢܬ',
  'ܦܕܝܬܐ',
  'ܓܝܓܠܐ',
  'ܟܡܝܪܐ',
  'ܡܚܪܣܦ',
  'ܪܘܦܫܐ',
  'ܫܘܪܒܐ',
  'ܡܚܪܙܦ',
  'ܡܙܢܓܪ',
  'ܡܕܩܕܩ',
  'ܒܪܘܢܐ',
  'ܕܝܬܩܐ',
  'ܛܒܘܥܐ',
  'ܝܐܝܒܐ',
  'ܓܕܘܕܐ',
  'ܡܬܚܡܢ',
  'ܡܟܬܒܐ',
  'ܡܓܢܓܪ',
  'ܟܝܡܝܐ',
  'ܗܠܝܘܡ',
  'ܡܛܒܚܐ',
  'ܐܢܬܬܐ',
  'ܒܨܝܪܐ',
  'ܟܘܪܚܐ',
  'ܩܝܪܛܐ',
  'ܓܪܘܕܐ',
  'ܦܫܝܡܐ',
  'ܩܐܡܝܐ',
  'ܬܫܥܣܪ',
]

export const WORDS_WITH_VOWELS = [
  'ܚܛܝܼܬ݂ܵܐ',
  'ܥܸܒܘܿܝܵܐ',
  'ܫܘܼܓ݂ܠܵܐ',
  'ܡܫܲܪܩܸܥ',
  'ܡܘܼܚܸܒܵܐ',
  'ܫܲܪܒܬܵܐ',
  'ܩܲܢܝܼܛܵܐ',
  'ܒܸܛܡܬ݂ܵܐ',
  'ܒܚܲܪܬ݂ܵܐ',
  'ܡܦܲܬ݂ܓܸܡ',
  'ܕܲܪܘܵܙܵܐ',
  'ܡܲܣܓܕ݂ܵܐ',
  'ܚܫܸܠܬܵܐ',
  'ܓܘܼܒ݂ܪܵܐ',
  'ܨܹܪܒܵܝܵܐ',
  'ܐܘܼܪܚܵܐ',
  'ܩܲܪܝܼܒ݂ܵܐ',
  'ܚܵܫܘܿܫܵܐ',
  'ܓܵܪܘܿܪܵܐ',
  'ܓܘܼܝܵܒ݂ܵܐ',
  'ܝܲܕ݂ܝܵܕ݂ܵܐ',
  'ܬܘܼܡܢܵܐ',
  'ܐܲܚܘܼܬܵܐ',
  'ܦܘܼܢܕܵܐ',
  'ܒܲܗܵܐܵܝܵܐ',
  'ܣܸܒܸܠܬܵܐ',
  'ܥܵܡܘܿܪܵܐ',
  'ܛܘܼܦ̮ܣܵܐ',
  'ܙܲܕܝܼܩܵܐ',
  'ܡܫܲܬܐܸܣ',
  'ܛܡܘܿܡܵܐ',
  'ܩܵܡܘܿܙܵܐ',
  'ܚܸܟ݂ܡܬ݂ܵܐ',
  'ܓܲܢ݇ܒܵܪܵܐ',
  'ܡܒܲܪܒܸܙ',
  'ܐܲܪܢܒ݂ܵܐ',
  'ܐܲܟܝܼܬܘܼ',
  'ܬܘܼܩܦܵܐ',
  'ܦܲܠܓܵܝܵܐ',
  'ܟܵܪܘܿܟ݂ܵܐ',
  'ܡܵܪܐܹܝܠ',
  'ܓܘܼܢܵܝܵܐ',
  'ܡܬܲܫܥܹܐ',
  'ܢܘܼܗܪܵܐ',
  'ܒܘܼܪܨܵܐ',
  'ܚܵܙܘܿܩܵܐ',
  'ܩܠܹܝܡܵܐ',
  'ܡܲܟ݂ܠܝܵܐ',
  'ܡܲܣܵܐ̈ܬ݂ܵܐ',
  'ܥܲܠܝܼܠܵܐ',
  'ܟܸܣܘܿܝܵܐ',
  'ܡܚܲܪܒܸܫ',
  'ܓܵܪܘܿܡܵܐ',
  'ܝܲܪܩܵܢܵܐ',
  'ܡܲܫܪܝܵܐ',
  'ܪܘܼܡܬܵܐ',
  'ܫܠܝܼܨܵܐ',
  'ܐܵܬ݂ܘܼܬ݂ܵܐ',
  'ܡܲܦܲܩܬܵܐ',
  'ܟܲܠܝܼܦ̮ܵܐ',
  'ܪܵܘܒܵܢܵܐ',
  'ܛܘܼܝܵܠܵܐ',
  'ܦܸܣܬܩܵܐ',
  'ܚܘܼܪܡܵܐ',
  'ܚܲܫܝܼܫܵܐ',
  'ܚܲܟܝܼܡܵܐ',
  'ܡܬܲܦܬܸܦ',
  'ܐܝܼܠܵܢܵܐ',
  'ܚܘܼܕܵܬܵܐ',
  'ܓܠܘܿܠܵܐ',
  'ܚܘܼܫܬܵܐ',
  'ܟܘܼܪܦܵܐ',
  'ܡܘܼܛܵܝܵܐ',
  'ܢܸܩܒܬ݂ܵܐ',
  'ܩܘܼܦܠܵܐ',
  'ܗܸܓ݂ܝܵܢܵܐ',
  'ܒܢܘܿܢܹ̈ܐ',
  'ܢܲܗܪܝܼܢ',
  'ܒܲܣܝܼܪܵܐ',
  'ܬܸܫܪܝܼܢ',
  'ܥܠܲܝܡܵܐ',
  'ܬܪܲܝܵܢܵܐ',
  'ܫܵܪܝܼܬ݂ܵܐ',
  'ܟܲܢܓܵܪܵܐ',
  'ܒܪܝܼܟ݂ܵܐ',
  'ܕܘܼܓܠܵܐ',
  'ܡܟܲܠܟܸܠ',
  'ܡܬܲܩܬܸܩ',
  'ܟܦܲܦܬܵܐ',
  'ܫܵܪܘܼܬܵܐ',
  'ܐܲܪܝܼܬܵܐ',
  'ܒܲܛܝܼܠܵܐ',
  'ܬܲܪܘܵܕ݂ܵܐ',
  'ܙܪܘܿܩܵܐ',
  'ܚܠܝܼܨܵܐ',
  'ܐܲܢܛܘܿܢ',
  'ܦܘܼܪܢܵܐ',
  'ܨܵܘܡܸܟܵܐ',
  'ܥܝܵܕܵܝܵܐ',
  'ܟܠܵܢܵܝܵܐ',
  'ܒܨܲܦܪܵܐ',
  'ܒܲܪܒܵܒ݂ܵܐ',
  'ܪܘܼܓ݂ܙܵܐ',
  'ܡܘܼܚܲܡܲܕ݂',
  'ܡܵܘܪܝܼܣ',
  'ܐܵܠܨܵܝܵܐ',
  'ܡܩܲܛܩܸܛ',
  'ܠܘܼܘܵܝܵܐ',
  'ܦܲܚܲܠܬܵܐ',
  'ܘܵܠܝܼܬܵܐ',
  'ܬܲܓܵܪܵܝܵܐ',
  'ܡܚܲܪܡܸܛ',
  'ܩܲܝܢܵܝܵܐ',
  'ܚܵܫܚܵܝܵܐ',
  'ܥܲܡܡܵܝܵܐ',
  'ܣܸܢܣܵܪܵܐ',
  'ܓ̰ܵܡܲܝܟܵܐ',
  'ܩܲܫܝܼܫܵܐ',
  'ܢܲܦܝܼܩܵܐ',
  'ܒܹܝܥܬ݂ܵܐ',
  'ܐܵܚܘܿܕ݂ܵܐ',
  'ܚܘܼܟܵܟ݂ܵܐ',
  'ܡܚܲܕܪܹܐ',
  'ܡܬܲܪܥܸܣ',
  'ܚܲܪܝܼܦܵܐ',
  'ܝܘܿܚܲܢܵܢ',
  'ܡܛܲܪܡܸܫ',
  'ܣܵܘܗܕܵܐ',
  'ܐ݇ܚܵܪܵܝܵܐ',
  'ܬܸܢܝܵܢܵܐ',
  'ܡܲܐܬ݂ܹܝܢ',
  'ܒܲܠܘܼܛܵܐ',
  'ܠܝܼܛܪܵܐ',
  'ܡܲܠܦܵܢܵܐ',
  'ܒܲܣܝܼܡܵܐ',
  'ܡܒܲܠܩܸܢ',
  'ܘܲܪܝܼܕ݂ܵܐ',
  'ܫܘܼܠܦܵܐ',
  'ܐܲܒܝܼܫܵܐ',
  'ܡܫܲܚܬܸܢ',
  'ܚܲܡܘܼܨܵܐ',
  'ܣܲܡܪܵܢܵܐ',
  'ܨܵܘܒܵܝܵܐ',
  'ܡܨܲܢܥܵܐ',
  'ܡܫܲܝܕ݂ܸܢ',
  'ܡܦܲܪܦܸܠ',
  'ܠܵܥܘܿܣܵܐ',
  'ܡܫܲܥܡܸܪ',
  'ܫܲܪܝܼܪܵܐ',
  'ܝܲܓ݂ܝܼܣܵܐ',
  'ܩܵܙܒ݂ܝܼܢ',
  'ܠܹܣܘܿܛܘܿ',
  'ܕܥܝܼܟ݂ܵܐ',
  'ܝܲܪܘܿܩܵܐ',
  'ܛܫܸܗܝܵܐ',
  'ܣܲܦ̮ܣܹܠܵܐ',
  'ܦܫܝܼܩܵܐ',
  'ܦܵܣܘܿܩܵܐ',
  'ܚܠܘܿܨܵܐ',
  'ܚܙܘܼܪܵܐ',
  'ܦܲܛܝܼܚܵܐ',
  'ܓܘܼܠܥܵܐ',
  'ܫܸܦܬܝܼܵܐ',
  'ܥܲܩܸܪܒ݂ܵܐ',
  'ܓܠܝܼܕܵܐ',
  'ܝܲܡܡܵܝܵܐ',
  'ܪܘܼܣܵܝܵܐ',
  'ܝܲܨܝܼܦܵܐ',
  'ܫܲܥܘܼܬ݂ܵܐ',
  'ܚܸܠܘܿܝܵܐ',
  'ܡܲܕܵܠܝܵܐ',
  'ܟܵܢܘܿܢܹ̈ܐ',
  'ܐܝܼܡܵܡܵܐ',
  'ܠܗܝܼܒ݂ܵܐ',
  'ܣܸܡܲܠܬܵܐ',
  'ܩܘܼܪܒܵܐ',
  'ܢܸܫܝܵܢܵܐ',
  'ܒܵܬ݂ܲܪܟܸܢ',
  'ܡܸܢܝܵܢܵܐ',
  'ܒܪܝܼܬܵܐ',
  'ܬܐܘܿܡܹ̈ܐ',
  'ܡܦܲܪܓܹܐ',
  'ܒܘܼܫܵܠܵܐ',
  'ܡܘܼܣܵܝܵܐ',
  'ܢܲܩܝܼܕ݂ܵܐ',
  'ܡܲܥܡܪܵܐ',
  'ܫܲܠܝܼܛܵܐ',
  'ܐܲܟܵܪܬܵܐ',
  'ܦܲܝܘܼܚܵܐ',
  'ܒܲܢܙܝܼܢ',
  'ܚܲܢܘܼܬܵܐ',
  'ܢܵܛܘܿܪܵܐ',
  'ܣܝܼܛܠܵܐ',
  'ܠܘܼܣܬܵܐ',
  'ܒܲܝܪܘܼܬ݂',
  'ܡܲܚܡܠܵܐ',
  'ܦ̮ܝܼܠܡܵܐ',
  'ܒܵܪܸܩܬܵܐ',
  'ܝܵܪܘܿܬ݂ܵܐ',
  'ܡܲܣܚܝܵܐ',
  'ܝܘܼܐܵܒ݂ܵܐ',
  'ܩܘܼܡܬܵܐ',
  'ܓܒ݂ܝܼܢܵܐ',
  'ܣܘܼܕ݂ܵܪܵܐ',
  'ܡܫܲܝܢܵܐ',
  'ܡܕܲܫܕܸܫ',
  'ܗܲܕܝܼܐܵܐ',
  'ܚܸܡܝܵܢܵܐ',
  'ܙܘܼܪܢܵܐ',
  'ܣܡܝܼܟ݂ܵܐ',
  'ܒܪܵܫܝܼܬ',
  'ܚܲܡܘܼܕ݂ܵܐ',
  'ܡܘܼܓܘܿܠ',
  'ܓܘܼܪܒ݂ܵܐ',
  'ܙܲܡܒܝܵܐ',
  'ܩܵܦܘܿܚܵܐ',
  'ܪܗ݇ܘܿܡܹܐ',
  'ܩܲܢܝܼܬܵܐ',
  'ܡܣܲܘܟܸܢ',
  'ܢܣܝܼܪܵܐ',
  'ܒܘܼܩܬܵܐ',
  'ܡܪܝܼܥܵܐ',
  'ܙܵܥܘܿܩܵܐ',
  'ܡܚܲܫܒܸܢ',
  'ܣܵܝܘܿܡܵܐ',
  'ܡܥܲܘܩܵܐ',
  'ܟܲܘܟ݂ܒ݂ܵܐ',
  'ܙܡܵܪܬܵܐ',
  'ܣܸܡܵܠܵܝܵܐ',
  'ܚܲܙܘܼܦܵܐ',
  'ܣܦܝܼܪܵܐ',
  'ܟܲܠܒܬ݂ܵܐ',
  'ܚܘܼܫܵܒ݂ܵܐ',
  'ܡܗܲܡܙܸܡ',
  'ܟ̰ܹܟܡܲܟ̰ܵܐ',
  'ܒܘܿܡܒܵܐ',
  'ܙܪܝܼܙܵܐ',
  'ܫܲܡܝܼܪܵܡ',
  'ܛܒ݂ܝܼܥܵܐ',
  'ܒܸܢܝܵܢܵܐ',
  'ܐܲܦܵܝܬܵܐ',
  'ܚܲܬܝܼܬ݂ܵܐ',
  'ܒܲܪܛܸܠܵܐ',
  'ܐܲܪܟܵܝܵܐ',
  'ܟܢܝܼܫܵܐ',
  'ܣܘܼܠܵܩܵܐ',
  'ܐܘܼܝܵܢܵܐ',
  'ܢܵܟ݂ܘܿܦܵܐ',
  'ܡܫܲܪܒܸܛ',
  'ܚܙܝܼܪܵܢ',
  'ܗܵܘܢܵܢܵܐ',
  'ܬܲܚܬܵܝܹ̈ܐ',
  'ܠܒ݂ܸܢ݇ܬܵܐ',
  'ܣܥܵܪܬ݂ܵܐ',
  'ܡܙܝܼܕ݂ܵܐ',
  'ܡܫܲܡܗܵܐ',
  'ܟܬ݂ܵܒ݂ܬܵܐ',
  'ܟܘܼܬ݂ܠܵܐ',
  'ܬܫܝܼܠܹܐ',
  'ܕܲܣܢܵܝܵܐ',
  'ܡܲܪܝܼܪܵܐ',
  'ܙܵܝܘܿܥܵܐ',
  'ܣܒ݂ܝܼܗܵܐ',
  'ܡܪܲܟܒ݂ܵܐ',
  'ܝܘܿܢܝܼܵܐ',
  'ܡܫܲܥܠܹܐ',
  'ܒܢܝܼܩܵܐ',
  'ܝܘܼܕܵܥܵܐ',
  'ܣܟܝܼܢܵܐ',
  'ܙܘܼܢܵܪܵܐ',
  'ܐܲܢ݇ܬܘܿܢ',
  'ܓܸܠܝܵܢܵܐ',
  'ܙܵܟ݂ܘܼܬ݂ܵܐ',
  'ܡܫܲܡܫܸܢ',
  'ܪܲܩܝܼܩܵܐ',
  'ܪܘܼܒ݂ܥܵܐ',
  'ܚܵܟ݂ܘܿܟ݂ܵܐ',
  'ܡܒܲܪܣܸܡ',
  'ܩܕܝܼܠܵܐ',
  'ܗܹܪܘܿܡܵܐ',
  'ܬܘܼܩܵܢܵܐ',
  'ܓܸܕܝܼܫܵܐ',
  'ܣܵܡܘܿܵܝܵܐ',
  'ܫܹܐܕ݂ܵܢܵܐ',
  'ܓܘܼܢܚܵܐ',
  'ܢܲܥܝܼܡܵܐ',
  'ܝܲܪܝܼܟ݂ܵܐ',
  'ܣܘܼܝܵܟ݂ܵܐ',
  'ܬܪܝܼܨܵܐ',
  'ܫܲܒܘܿܛܵܐ',
  'ܛܘܼܝܵܣܵܐ',
  'ܚܘܼܠܵܦܵܐ',
  'ܬܲܝܡܢܵܐ',
  'ܡܲܪܫܡܵܐ',
  'ܐ݇ܙܵܠܬܵܐ',
  'ܗܲܘܝܼܐܵܐ',
  'ܬܲܢܘܼܪܵܐ',
  'ܢܸܙܠܬ݂ܵܐ',
  'ܒܕܘܼܟܲܬ݂',
  'ܒܪܲܡܫܵܐ',
  'ܩܵܢܘܿܢܵܐ',
  'ܐܵܪܘܿܢܵܐ',
  'ܡܣܲܡܣܸܩ',
  'ܒܘܼܪܵܟ݂ܵܐ',
  'ܐܘܿܢܹܝܠ',
  'ܡܲܢܕܵܝܵܐ',
  'ܡܣܲܩܠܸܩ',
  'ܬܲܪܡܵܠܵܐ',
  'ܡܟܲܪܦܸܫ',
  'ܩܠܵܝܬܵܐ',
  'ܫܘܼܪܬ݂ܵܐ',
  'ܡܕܝܼܢܵܐ',
  'ܚܲܒܘܿܛܵܐ',
  'ܡܨܲܚܨܹܐ',
  'ܡܩܲܢܘܸܢ',
  'ܡܥܲܪܘܸܨ',
  'ܚܵܫܘܿܠܵܐ',
  'ܛܘܼܪܵܝܵܐ',
  'ܗܡܲܝܪܵܐ',
  'ܠܲܒܵܬ݇ܪܵܐ',
  'ܥܸܢܝܵܢܵܐ',
  'ܩܪܝܼܚܵܐ',
  'ܦܬ݂ܝܼܚܵܐ',
  'ܚܸܙܘܵܢܵܐ',
  'ܡܲܠܩܛܵܐ',
  'ܫܝܼܪܵܝܵܐ',
  'ܟܪܝܼܒܵܐ',
  'ܥܲܪܦܸܠܵܐ',
  'ܩܵܪܘܼܪܵܐ',
  'ܟܲܪܡܠܸܫ',
  'ܐܲܠܦܵܝܵܐ',
  'ܥܸܣܪ̈ܲܝܹܐ',
  'ܩܢܲܫܬܵܐ',
  'ܥܠܘܿܝܵܐ',
  'ܡܲܚܠܦܵܐ',
  'ܛܲܐܠܵܢܵܐ',
  'ܓܲܡܒܝܵܐ',
  'ܓܘܿܪܓ̰ܵܐ',
  'ܙܡܝܼܢܵܐ',
  'ܨܠܝܼܒ݂ܵܐ',
  'ܡܲܛܚܵܢܵܐ',
  'ܦ̮ܘܼܫܵܝܵܐ',
  'ܡܓܲܢܓܸܙ',
  'ܦ̮ܪܵܒ݂ܠܵܐ',
  'ܟܲܫܝܼܪܵܐ',
  'ܠܘܼܩܵܛܵܐ',
  'ܟ̰ܝܼܢܵܝܵܐ',
  'ܓܵܫܘܿܫܵܐ',
  'ܐܝܼܠܘܿܠ',
  'ܣܘܼܪܵܝܵܐ',
  'ܨܵܘܲܪܬܵܐ',
  'ܐܝܼܡܲܝܠ',
  'ܓܡܲܠܬܵܐ',
  'ܡܝܼܠܹܛܹܐ',
  'ܟܦܝܼܢܵܐ',
  'ܬܘܼܠܬ݂ܵܐ',
  'ܡܚܲܡܚܸܡ',
  'ܡܝܲܬܪܵܐ',
  'ܡܬܲܦ̮ܬܸܫ',
  'ܡܣܲܪܥܸܦ',
  'ܩܵܝܘܿܡܵܐ',
  'ܣܒ݂ܝܼܪܵܐ',
  'ܛܘܼܟܵܣܵܐ',
  'ܟܢܝܼܙܵܐ',
  'ܫܒ݂ܵܒ݂ܵܝܵܐ',
  'ܫܚܘܿܡܵܐ',
  'ܫܸܡܚܵܢܵܐ',
  'ܘܲܪܘܿܕܵܐ',
  'ܨܝܼܢܵܝܵܐ',
  'ܬܲܦܢܟ݂ܵܐ',
  'ܬܪܘܼܨܵܐ',
  'ܚܕܵܢܵܝܵܐ',
  'ܫܵܩܝܼܬ݂ܵܐ',
  'ܡܲܣܪܛܵܐ',
  'ܘܵܠܝܵܝܵܐ',
  'ܡܵܘܥܕܵܐ',
  'ܡܓܲܠܓܸܠ',
  'ܣܘܼܓܵܠܵܐ',
  'ܒܢܲܦ̮ܫܵܐ',
  'ܦܘܼܬܵܚܵܐ',
  'ܡܣܲܡܣܸܡ',
  'ܢܝܼܢܘܹܐ',
  'ܩܘܼܪܛܵܐ',
  'ܪܲܓܘܼܫܵܐ',
  'ܙܘܼܗܵܪܵܐ',
  'ܦܲܬ݂ܚܬܵܐ',
  'ܫܘܼܒ݂ܥܵܐ',
  'ܩܘܼܛܒܵܐ',
  'ܒܣܲܒ݂ܪܵܐ',
  'ܟܸܪܟ݂ܵܢܵܐ',
  'ܢܵܒ݂ܸܓܬܵܐ',
  'ܩܘܼܠܵܥܵܐ',
  'ܟܵܘܸܬ݂ܪܵܐ',
  'ܟܪܝܼܗܵܐ',
  'ܡܸܨܪܵܝܵܐ',
  'ܦܫܝܼܛܵܐ',
  'ܡܫܲܘܚܸܪ',
  'ܐܲܠܩܘܿܫ',
  'ܣܲܘܦܵܝܵܐ',
  'ܚܵܕ݂ܸܪܘܵܢ',
  'ܫܘܼܚܕܵܐ',
  'ܟܘܿܪܝܼܵܐ',
  'ܓܘܼܒܬ݂ܵܐ',
  'ܫܲܪܘܵܝܵܐ',
  'ܡܲܓܵܢܵܝܵܐ',
  'ܫܵܒ݂ܘܿܥܵܐ',
  'ܥܣܲܪܬܵܐ',
  'ܠܲܩܠܵܩܵܐ',
  'ܢܲܨܝܼܚܵܐ',
  'ܚܲܘܒܬ݂ܵܐ',
  'ܪܸܥܝܵܢܵܐ',
  'ܡܪܵܪܬܵܐ',
  'ܡܲܩܪܵܢܵܐ',
  'ܚܲܝܘܬ݂ܵܐ',
  'ܬܘܼܫܥܵܐ',
  'ܦܸܬ݂ܓ݂ܵܡܵܐ',
  'ܥܲܬܝܼܩܵܐ',
  'ܘܲܙܝܼܪܵܐ',
  'ܡܚܲܙܕܸܓ',
  'ܦܵܬܸܠܓܵܢ',
  'ܣܵܘܟܵܢܵܐ',
  'ܨܸܒ݂ܝܵܢܵܐ',
  'ܝܘܼܗ݇ܒ݂ܵܐ',
  'ܓܵܘܢܵܢܵܐ',
  'ܕܡܝܼܟ݂ܵܐ',
  'ܣܡܘܿܩܵܐ',
  'ܝܲܩܘܼܪܵܐ',
  'ܪܘܲܢܕܵܐ',
  'ܒܠܝܼܓ݂ܵܐ',
  'ܡܒܲܕ݂ܒܸܕ݂',
  'ܕܘܼܟܬ݂ܵܐ',
  'ܡܝܼܛܪܵܐ',
  'ܕܲܩܝܼܩܵܐ',
  'ܡܸܠܝܘܿܢ',
  'ܓܠܝܼܙܵܐ',
  'ܫܘܼܪܵܝܵܐ',
  'ܕܵܡܝܵܢܵܐ',
  'ܠܒܨܝܼܪ',
  'ܚܘܼܨܵܪܵܐ',
  'ܕܵܘܟ݂ܠܵܐ',
  'ܦܪܝܼܫܵܐ',
  'ܐܵܐܲܪܵܝܵܐ',
  'ܦܘܼܟܪܵܐ',
  'ܐܲܪܒܥܵܐ',
  'ܡܣܲܙܓܸܪ',
  'ܙܩܝܼܦܵܐ',
  'ܥܲܙܝܼܙܵܐ',
  'ܡܗܲܘܢܸܢ',
  'ܓܘܼܒܵܝܵܐ',
  'ܐܘܼܪܙܵܐ',
  'ܡܙܲܥܙܸܥ',
  'ܡܥܲܪܦܸܠ',
  'ܫܟܝܼܪܵܐ',
  'ܫܘܼܐܵܠܵܐ',
  'ܡܲܘܠܵܕ݂ܵܐ',
  'ܚܘܼܪܕܵܐ',
  'ܓ̰ܘܲܢܩܵܐ',
  'ܡܨܲܪܦܸܚ',
  'ܐܲܣܘܼܪܵܐ',
  'ܐܸܨܛܲܚܸܢ',
  'ܠܲܬܒ݂ܝܼܵܐ',
  'ܚܘܼܝܵܨܵܐ',
  'ܒ̮ܝܼܛܢܵܡ',
  'ܗܸܠܝܘܿܢ',
  'ܛܥܝܼܫܵܐ',
  'ܡܸܚܕܵܝܵܐ',
  'ܟܘܼܢܵܝܵܐ',
  'ܚܠܘܿܠܵܐ',
  'ܪܩܝܼܥܵܐ',
  'ܥܲܠܐܲܣܵܣ',
  'ܡܸܥܙܬܵܐ',
  'ܬܲܘܬܵܪܵܐ',
  'ܩܲܛܝܼܢܵܐ',
  'ܩܸܛܥܵܢܵܐ',
  'ܣܘܼܣܬܵܐ',
  'ܗܲܝܟܠܵܐ',
  'ܒܘܼܥܕܵܐ',
  'ܓܵܨܘܿܨܵܐ',
  'ܒܲܪܢܵܫܵܐ',
  'ܐܸܚܕ݂ܵܕ݂ܹܐ',
  'ܡܕܲܡܕܸܡ',
  'ܟܘܼܫܵܝܵܐ',
  'ܡܦܲܪܡܹܐ',
  'ܦܲܥܝܼܠܵܐ',
  'ܛܵܠܘܿܡܵܐ',
  'ܓܲܙܵܐܝܼܪ',
  'ܓܒ݂ܵܪܬܵܐ',
  'ܡܲܕܢܚܵܐ',
  'ܪܒ݂ܝܼܥܵܐ',
  'ܫܓ݂ܝܼܡܵܐ',
  'ܣܘܼܦܪܵܐ',
  'ܦܸܢܕܩܵܐ',
  'ܡܫܲܠܗܸܒ݂',
  'ܬܟ݂ܝܼܠܵܐ',
  'ܡܲܥܪܒ݂ܵܐ',
  'ܙܥܘܿܪܵܐ',
  'ܢܵܨܪܵܝܵܐ',
  'ܣܲܪܛܵܢܵܐ',
  'ܢܝܼܠܘܿܣ',
  'ܙܵܘܝܼܬ݂ܵܐ',
  'ܡܙܲܪܙܸܛ',
  'ܫܡܘܼܢܝܼ',
  'ܟܘܿܐܵܠܵܐ',
  'ܡܫܲܪܬܸܚ',
  'ܡܲܒܘܼܥܵܐ',
  'ܐܲܣܝܼܪܵܐ',
  'ܡܪܲܦܪܸܦ',
  'ܦܵܪܣܵܝܵܐ',
  'ܐܸܫܛܵܪܵܐ',
  'ܓܲܒ݂ܪܝܼܹܠ',
  'ܡܲܕܪܝܼܕ݂',
  'ܥܸܪܙܵܠܵܐ',
  'ܡܦܲܪܦܸܥ',
  'ܡܚܲܕܬܵܐ',
  'ܡܫܵܓ݂ܬܵܐ',
  'ܫܘܼܝܵܫܵܐ',
  'ܢܘܼܪܵܢܵܐ',
  'ܡܸܬ݂ܚܙܹܐ',
  'ܩܲܕ݇ܡܵܝܵܐ',
  'ܡܓܲܪܓܸܫ',
  'ܐܸܫܬܝܼܢ',
  'ܕܘܼܟܵܝܵܐ',
  'ܡܸܨܥܵܝܵܐ',
  'ܫܲܦܝܼܪܵܐ',
  'ܩܲܗܝܼܪܵܐ',
  'ܒܸܪܩܵܝܵܐ',
  'ܡܲܣܪܩܵܐ',
  'ܚܲܡܫܝܼܢ',
  'ܣܝܼܢܲܡܵܐ',
  'ܫܹܐܪܬܵܐ',
  'ܬܲܡܝܼܡܵܐ',
  'ܦܸܠܦܠܵܐ',
  'ܡܲܦܩܵܢܵܐ',
  'ܡܵܘܒܠܵܐ',
  'ܚܙܵܝܬܵܐ',
  'ܥܵܘܝܡܵܐ',
  'ܪܘܼܚܩܵܐ',
  'ܢܘܼܨܦܵܐ',
  'ܕܘܼܢܝܹܐ',
  'ܥܲܢܟܵܒ݂ܵܐ',
  'ܚܘܼܡܵܠܵܐ',
  'ܨܵܘܠܬܵܐ',
  'ܐܲܬܘܼܢܵܐ',
  'ܛܘܼܝܵܒ݂ܵܐ',
  'ܐܵܛܘܿܡܵܐ',
  'ܡܚܝܼܠܵܐ',
  'ܒܵܒ݂ܘܿܢܵܐ',
  'ܒܵܚܪܹܝܢ',
  'ܫܸܪܫܵܝܵܐ',
  'ܡܸܚܕ݂ܵܕ݂ܹܐ',
  'ܪܹܝܫܵܝܵܐ',
  'ܒܚܲܝܠܵܐ',
  'ܢܵܡܘܿܣܵܐ',
  'ܝܲܬܘܼܡܵܐ',
  'ܡܒܲܨܒܸܨ',
  'ܗܲܠܡܘܿܢ',
  'ܥܘܼܡܪܵܐ',
  'ܡܫܲܥܒܸܕ݂',
  'ܬܡܵܢܝܼܢ',
  'ܙܠܝܼܡܵܐ',
  'ܢܵܣܘܿܟ݂ܵܐ',
  'ܗܲܝܲܪܬܵܐ',
  'ܦܸܛܪܘܿܣ',
  'ܝܲܬܵܒ݂ܬܵܐ',
  'ܟ̰ܡܝܼܥܵܐ',
  'ܗܲܢܝܼܐܵܐ',
  'ܛܲܝܵܣܬܵܐ',
  'ܡܲܫܛܵܚܵܐ',
  'ܡܲܫܬܝܵܐ',
  'ܚܲܒܝܼܒ݂ܵܐ',
  'ܡܸܬ݂ܟܬ݂ܹܒ݂',
  'ܡܕܲܠܩܸܒ݂',
  'ܥܒ݂ܝܼܕ݂ܵܐ',
  'ܡܩܲܘܡܵܐ',
  'ܡܘܼܕܵܟ݂ܵܐ',
  'ܥܵܠܘܿܠܵܐ',
  'ܚܲܡܝܼܡܵܐ',
  'ܟܲܪܣܵܢܵܐ',
  'ܝܵܣܡܝܼܢ',
  'ܡܫܲܘܕܸܥ',
  'ܡܩܲܢܛܸܪ',
  'ܒܲܬ݂ܫܒ݂ܲܥ',
  'ܕܘܼܝܵܩܵܐ',
  'ܐܵܣܝܼܬܵܐ',
  'ܡܨܲܢܨܸܠ',
  'ܩܲܕܝܼܡܵܐ',
  'ܛܲܒܵܢܓ̰ܵܐ',
  'ܚܠܝܼܦܵܐ',
  'ܡܬܲܢܬܸܢ',
  'ܫܠܝܼܡܵܐ',
  'ܫܬܵܝܬܵܐ',
  'ܐܲܠܦܹܝܢ',
  'ܒ̮ܝܼܕܝܘܿ',
  'ܝܘܼܦܩܵܐ',
  'ܐܸܕܝܘܿܡ',
  'ܫܛܝܼܚܵܐ',
  'ܟܪܵܦܬܵܐ',
  'ܓܘܼܝܵܢܵܐ',
  'ܡܲܣܡܟ݂ܵܐ',
  'ܕܲܪܵܓ݂ܬܵܐ',
  'ܐ݇ܬ݂ܵܝܬܵܐ',
  'ܪܲܕܘܼܕܵܐ',
  'ܗܹܡܸܙܡܵܢ',
  'ܠܵܚܡܵܝܵܐ',
  'ܣܘܵܕܵܝܵܐ',
  'ܚܲܫܘܼܟܵܐ',
  'ܟܲܕܝܼܒ݂ܵܐ',
  'ܟܘܿܢܓܘܿ',
  'ܢܲܪܒ݂ܹܝܓ',
  'ܬܫܹܟ݂ܵܝܵܐ',
  'ܡܫܲܡܠܹܐ',
  'ܥܘܼܣܪܵܐ',
  'ܓܘܼܫܡܵܐ',
  'ܩܘܼܦܣܵܐ',
  'ܦܵܝܫܵܢܵܐ',
  'ܡܲܘܬܵܢܵܐ',
  'ܚܒ݂ܝܼܨܵܐ',
  'ܣܘܼܟܵܠܵܐ',
  'ܪܓ݂ܝܼܙܵܐ',
  'ܢܲܬܝܼܓ̰ܵܐ',
  'ܒܵܠܘܿܢܵܐ',
  'ܗܘܼܦܵܟ݂ܵܐ',
  'ܕܲܒܘܼܫܵܐ',
  'ܐܲܢܬܹܝܢ',
  'ܦܵܘܠܘܿܣ',
  'ܚܵܙܘܿܝܵܐ',
  'ܛܒ݂ܝܼܒ݂ܵܐ',
  'ܫܒ݂ܲܛܪܵܐ',
  'ܡܬܲܦܢܸܟ݂',
  'ܐܲܚܘܿܢܵܐ',
  'ܪܲܛܝܼܒ݂ܵܐ',
  'ܣܘܼܪܦܵܐ',
  'ܪܲܚܘܼܩܵܐ',
  'ܡܬܲܪܓܸܡ',
  'ܓܵܘܵܐܝܼܬ݂',
  'ܡܟܲܪܡܸܟ݂',
  'ܣܘܼܟܪܵܐ',
  'ܡܲܬ݂ܩܢܵܐ',
  'ܡܲܠܘܵܫܵܐ',
  'ܦܸܠܥܵܢܵܐ',
  'ܙܵܪܝܼܦܵܐ',
  'ܓܸܪܡܵܘܵܐ',
  'ܣܘܼܪܵܩܵܐ',
  'ܡܟ̰ܲܙܟ̰ܸܙ',
  'ܡܫܲܘܬܸܒ݂',
  'ܡܫܲܘܬܸܦ',
  'ܡܟ̰ܲܩܟ̰ܸܩ',
  'ܚܕ݂ܲܥܣܲܪ',
  'ܕܲܥܬܝܼܕ',
  'ܥܲܘܩܵܢܵܐ',
  'ܒܡܸܬ݂ܚܵܐ',
  'ܬܲܠܝܼܠܵܐ',
  'ܣܢܝܼܩܵܐ',
  'ܣܦܝܼܩܵܐ',
  'ܘܫܲܪܟܵܐ',
  'ܬܲܢܲܝܬܵܐ',
  'ܡܓܲܪܓܸܡ',
  'ܡܛܲܠܬ݂ܵܐ',
  'ܕܲܒܵܫܬܵܐ',
  'ܟܲܒܝܼܪܵܐ',
  'ܡܬܲܫܘܸܫ',
  'ܡܣܲܚܒܸܪ',
  'ܩܘܼܒܵܠܵܐ',
  'ܚܘܵܪܵܝܵܐ',
  'ܥܲܠܥܵܠܵܐ',
  'ܐܘܼܦܩܵܐ',
  'ܚܵܫܘܿܒ݂ܵܐ',
  'ܟܟ̰ܝܼܚܵܐ',
  'ܡܬܲܠܬܹܐ',
  'ܩܘܼܬܝܼܵܐ',
  'ܣܲܓܝܼܐܵܐ',
  'ܥܘܼܪܠܵܐ',
  'ܡܫܲܩܫܸܩ',
  'ܚܘܿܓ݂ܬܵܐ',
  'ܚܘܼܪܵܪܵܐ',
  'ܡܹܐܦܝܵܐ',
  'ܥܲܪܒ݂ܝܼܵܐ',
  'ܡܢܲܣܝܵܐ',
  'ܫܲܒ݂ܥܝܼܢ',
  'ܐܵܫܝܼܬ݂ܵܐ',
  'ܝܵܠܘܿܦܵܐ',
  'ܟܘܼܫܦܵܐ',
  'ܡܲܬ݂ܩܵܠܵܐ',
  'ܡܲܬܝܼܢܵܐ',
  'ܡܒܲܠܒܸܠ',
  'ܐܲܕ݇ܫܲܢ݇ܬ',
  'ܨܸܒܥܬ݂ܵܐ',
  'ܩܵܛܘܿܢܵܐ',
  'ܦܲܠܝܼܛܵܐ',
  'ܫܘܝܼܨܵܐ',
  'ܡܵܐܟܹܢܵܐ',
  'ܫܵܚܘܿܠܵܐ',
  'ܫܚܝܼܡܵܐ',
  'ܫܢܝܼܙܵܐ',
  'ܡܟ݂ܝܼܪܵܐ',
  'ܦܲܪܠܵܡܵܢ',
  'ܫܲܒܘܼܪܵܐ',
  'ܫܲܚܝܼܢܵܐ',
  'ܢܘܼܓ݂ܗܵܐ',
  'ܨܘܼܢܵܡܝܼ',
  'ܦܪܵܫܬܵܐ',
  'ܡܲܥܠܵܢܵܐ',
  'ܡܘܼܢܵܚܵܐ',
  'ܒܪܵܙܝܼܠ',
  'ܪܵܓ݂ܘܿܠܵܐ',
  'ܡܲܠܘܼܚܵܐ',
  'ܐܲܝܠܹܝܢ',
  'ܝܲܒܝܼܫܵܐ',
  'ܩܛܘܿܡܵܐ',
  'ܡܚܲܦܪܸܦ',
  'ܦܠܲܢܹܛܵܐ',
  'ܡܸܨܪܹܝܢ',
  'ܡܠܝܼܠܵܐ',
  'ܟܬ݂ܵܝܬܵܐ',
  'ܟܘܼܠܒܵܐ',
  'ܡܪܲܬܪܸܬ',
  'ܡܙܵܘܓ݂ܵܐ',
  'ܛܵܝܸܠܬܵܐ',
  'ܠܸܚܵܝܦܵܐ',
  'ܓܲܪܒܝܵܐ',
  'ܥܪܝܼܨܵܐ',
  'ܝܲܠܕܬܵܐ',
  'ܫܸܬܩܲܕ݇ܝ',
  'ܩܲܠܘܼܠܵܐ',
  'ܟܝܼܣܬܵܐ',
  'ܦܝܼܬܣܵܐ',
  'ܡܒܲܠܕܸܪ',
  'ܟܲܡܘܼܕܵܐ',
  'ܡܥܲܠܬܸܢ',
  'ܟܵܒ݂ܘܿܫܵܐ',
  'ܡܲܦܬܵܚܵܐ',
  'ܚܲܣܝܼܢܵܐ',
  'ܪܗܝܼܒ݂ܵܐ',
  'ܐܲܡܝܼܢܵܐ',
  'ܡܣܲܩܒܸܠ',
  'ܒܲܪܒܵܪܵܐ',
  'ܝܵܠܸܚܬܵܐ',
  'ܥܲܡܘܼܩܵܐ',
  'ܝܠܝܼܕ݂ܵܐ',
  'ܝܘܼܪܟܵܐ',
  'ܡܲܫܵܚܲܛܵܐ',
  'ܬܡܵܢܝܵܐ',
  'ܪܬ݂ܝܼܚܵܐ',
  'ܝܲܬܝܼܪܵܐ',
  'ܡܓܲܪܒ݂ܸܢ',
  'ܝܘܼܠܝܼܵܐ',
  'ܦܸܢܓܵܢܵܐ',
  'ܝܲܡܝܼܢܵܐ',
  'ܐܵܬ݂ܝܵܢܵܐ',
  'ܦܲܩܘܼܚܵܐ',
  'ܢܘܼܐܹܝܠ',
  'ܡܸܠܝܵܪܕ݇',
  'ܡܲܩܠܝܵܐ',
  'ܣܩܘܿܡܵܐ',
  'ܩܘܼܕܡܹܐ',
  'ܬܪܸܥܣܲܪ',
  'ܚܘܼܡܫܵܐ',
  'ܩܘܼܪܨܵܐ',
  'ܒܲܗܘܼܪܵܐ',
  'ܫܟ݂ܝܼܚܵܐ',
  'ܡܙܲܡܙܸܡ',
  'ܫܘܼܚܬܵܐ',
  'ܣܵܚܝܵܢܵܐ',
  'ܦܝܼܠܵܣܵܐ',
  'ܕܘܼܟܵܢܵܐ',
  'ܐܲܡܘܼܪܵܐ',
  'ܬܸܪܨܵܢܵܐ',
  'ܩܹܒ݂ܘܿܬܵܐ',
  'ܫܵܢܝܵܢܵܐ',
  'ܚܘܼܠܵܢܵܐ',
  'ܡܣܲܪܗܸܒ݂',
  'ܥܸܒ݂ܪܵܝܵܐ',
  'ܣܲܘܦܵܢܵܐ',
  'ܨܒ݂ܘܼܬ݂ܵܐ',
  'ܡܕܲܫ̃ܡܸܢ',
  'ܐܝܼܣܚܵܩ',
  'ܬܘܼܓܵܓ݂ܵܐ',
  'ܐܘܼܫܦܵܐ',
  'ܟ̰ܘܿܬܩܵܐ',
  'ܡܲܡܠܠܵܐ',
  'ܡܛܲܪܛܸܡ',
  'ܒܘܼܩܵܪܵܐ',
  'ܥܲܬܝܼܕ݂ܵܐ',
  'ܐܲܨܠܵܝܵܐ',
  'ܢܘܼܩܙܵܐ',
  'ܓܘܼܠܦܵܐ',
  'ܡܗܝܼܪܵܐ',
  'ܬܸܫܥܝܼܢ',
  'ܐ݇ܚܝܵܢܵܐ',
  'ܢܸܥܡܬ݂ܵܐ',
  'ܠܘܿܥܬܵܐ',
  'ܚܠܝܼܡܵܐ',
  'ܚܲܕܲܬܬܵܐ',
  'ܚܲܠܲܩܬܵܐ',
  'ܒܲܠܚܘܿܕ݂',
  'ܐ݇ܢܵܫܵܝܵܐ',
  'ܫܵܘܝܼܬ݂ܵܐ',
  'ܢܘܼܣܟ݂ܵܐ',
  'ܒܲܩܠܵܘܵܐ',
  'ܐܝܼܕ݂ܡܵܐ',
  'ܣܸܢܐܬ݂ܵܐ',
  'ܢܕ݂ܝܼܕ݂ܵܐ',
  'ܚܘܼܕܪܵܐ',
  'ܫܲܩܠܵܒ݂ܵܐ',
  'ܛܘܼܦܪܵܐ',
  'ܙܵܒ݂ܘܿܢܵܐ',
  'ܫܸܦܘܿܠܵܐ',
  'ܟܲܗܪܵܒ݂ܵܐ',
  'ܐܸܫܟܵܦܵܐ',
  'ܡܩܲܪܡܸܟ̰',
  'ܝܲܠܦܘܼܟ',
  'ܡܒܲܙܒܸܙ',
  'ܐ݇ܟܘܿܡܵܐ',
  'ܡܸܕ݂ܝܵܪܵܐ',
  'ܒܵܒܘܼܬܵܐ',
  'ܡܝܲܩܪܵܐ',
  'ܣܸܛܪܵܝܵܐ',
  'ܡܫܲܚܠܸܦ',
  'ܡܥܝܼܢܵܐ',
  'ܦ̮ܝܼܙܝܼܵܐ',
  'ܡܦܲܪܦܸܫ',
  'ܡܲܒܢܝܵܐ',
  'ܥܸܣܩܬ݂ܵܐ',
  'ܫܲܟܲܪܬܵܐ',
  'ܕܘܼܪܵܪܵܐ',
  'ܡܲܥܲܠܬܵܐ',
  'ܪܲܕܵܝܬܵܐ',
  'ܨܵܪܘܿܚܵܐ',
  'ܐ݇ܚܹܪ݇ܢܵܐ',
  'ܛܠܵܦ̮ܚܵܐ',
  'ܠܲܩܵܕ݇ܡܵܐ',
  'ܫܵܓ݂ܘܿܡܵܐ',
  'ܡܫܲܪܫܸܪ',
  'ܡܩܲܪܩܸܪ',
  'ܒܸܠܓܝܵܐ',
  'ܐܸܓܲܪܬܵܐ',
  'ܒܫܝܼܠܵܐ',
  'ܡܘܲܙܘܸܙ',
  'ܬܲܪܬܹܝܢ',
  'ܕܪܵܥܢܵܐ',
  'ܗܸܢܝܵܢܵܐ',
  'ܟܬ݂ܝܼܒ݂ܵܐ',
  'ܡܟܲܠܒܸܢ',
  'ܚܲܕ݇ܟܡܵܐ',
  'ܪܨܝܼܦܵܐ',
  'ܟܝܘܼܠܵܐ',
  'ܛܲܥܝܼܡܵܐ',
  'ܨܘܼܕܪܵܐ',
  'ܪܵܡܘܿܙܵܐ',
  'ܥܲܬܝܼܪܵܐ',
  'ܬܘܼܬܵܢܵܐ',
  'ܥܘܼܕܵܠܹܐ',
  'ܥܸܣܪܝܼܢ',
  'ܨܘܼܒ݂ܥܵܐ',
  'ܝܩܝܼܕܵܐ',
  'ܒܗܸܦܟܵܐ',
  'ܥܲܡܘܼܛܵܐ',
  'ܕܘܼܪܬܵܐ',
  'ܡܗܲܝܡܸܢ',
  'ܙܓ݂ܝܼܪܵܐ',
  'ܩܘܼܪܐܵܢ',
  'ܚܘܼܪܓܵܐ',
  'ܟܘܼܚܬܵܐ',
  'ܛܲܦܵܝܬܵܐ',
  'ܦܵܬ݂ܘܿܪܵܐ',
  'ܫܵܪܘܿܩܵܐ',
  'ܡܲܛܥܵܡܵܐ',
  'ܡܲܒ݂ܩܠܵܐ',
  'ܢܲܝܙܟ݂ܵܐ',
  'ܥܲܪܒܵܢܵܐ',
  'ܛܵܝܘܿܣܵܐ',
  'ܡܲܥܒܪܵܐ',
  'ܡܟܲܫܟܸܫ',
  'ܡܢܲܘܢܹܐ',
  'ܫܒ݂ܲܥܣܲܪ',
  'ܫܘܼܬܬ݂ܵܐ',
  'ܙܝܼܘܵܢܵܐ',
  'ܪܸܡܘܼܢܵܐ',
  'ܟܠܫܲܢ݇ܬ',
  'ܦܸܕ݂ܝܼܬ݂ܵܐ',
  'ܓܝܼܓ݂ܠܵܐ',
  'ܟܵܡܝܼܪܵܐ',
  'ܡܚܲܪܣܸܦ',
  'ܪܘܼܦ̮ܫܵܐ',
  'ܫܘܼܪܒ݂ܵܐ',
  'ܡܚܲܪܙܸܦ',
  'ܡܙܲܢܓܸܪ',
  'ܡܕܲܩܕܸܩ',
  'ܒܪܘܿܢܵܐ',
  'ܕܝܼܵܬ݂ܹܩܹܐ',
  'ܛܵܒ݂ܘܿܥܵܐ',
  'ܝܲܐܝܼܒ݂ܵܐ',
  'ܓܲܕܘܼܕܵܐ',
  'ܡܬܲܚܡܸܢ',
  'ܡܲܟ݂ܬܒ݂ܵܐ',
  'ܡܓ̰ܲܢܓ̰ܸܪ',
  'ܟܝܼܡܝܼܵܐ',
  'ܗܸܠܝܘܿܡ',
  'ܡܲܛܒܚܵܐ',
  'ܐܲܢ݇ܬܬ݂ܵܐ',
  'ܒܨܝܼܪܵܐ',
  'ܟܘܼܪܚܵܐ',
  'ܩܝܼܪܵܛܵܐ',
  'ܓܸܪܘܿܕ݂ܵܐ',
  'ܦܫܝܼܡܵܐ',
  'ܩܵܐܹܡܵܝܵܐ',
  'ܬܫܲܥܣܲܪ',
]

export const DEFINITIONS = [
  'sin, fault',
  'swell, swelling',
  'job, work, labor, occupation; affair, business',
  'to crack, crackle, snap; to crack a joint',
  'beloved, loved; sweetheart, darling',
  'generation, genealogy; tribe, clan, nation, stock; order, rank; etymology; family; race (large genealogical group)',
  'dangerous, hazardous, perilous',
  'terebinth; peanut, pistachio',
  'in the following, then; later, afterwards, in the future; at the end',
  'to articulate, phrase',
  'gate, gateway; portal; title page, front page',
  'mosque, masjid',
  'ornament, a piece of jewellery; jewellery',
  'Alternative form of ܓܘܼܪܵܐ (gūrā, “great, big, large”)',
  'Serbian (person from Serbia or of Serbian descent)',
  'road, way, path; behavior, conduct, manners, way, lifestyle; journey, manner, mode, technique, method, way; opportunity, room',
  'best man (primary attendant to the groom in a wedding); relative (someone in the same family connected by blood, marriage, or adoption); local (person who lives near a given place); godfather',
  'empathetic, sympathetic, pitiful; passive, accusative',
  'puller, tower, drawer',
  'response, reaction, reply; reaction; reaction, chemical reaction',
  'millipede; centipede',
  'eighth (⅛)',
  'brotherhood, fraternity',
  'candle (light source consisting of a wick embedded typically in wax)',
  'Baháʼí (pertaining to Baháʼís or beliefs held in the Baháʼí Faith)',
  'staircase, stairway (flight of stairs)',
  'Gomorrah',
  'example, symbol, allegory; type, kind, form, figure',
  'righteous; just, moral',
  'to found, establish, set up',
  'solid',
  'rubber; resin, gum; gum arabic',
  'wisdom, knowledge, science, philosophy; counsel, judgement; scheme, plan; expertness, skill, art',
  'a surname',
  'to scatter, to disperse; to scatter, to disperse, to be scattered or dispersed',
  'Lepus',
  'Akitu (the Assyrian-Babylonian new year festival celebrated annually on the first of April)',
  'power, strength; fortitude, endurance; energy',
  'Noun characterized by the above adjective; middle child',
  'tourist; traveler, wanderer, roamer',
  'a male given name, Marel',
  'insult, contumely; reproach, blame, complaint; calumny',
  'to narrate history, give the history of',
  'a female given name',
  'stock market, stock exchange, bourse',
  'traveler',
  'region, climate, clime (geographical area of the earth, particularly a zone of latitude); territory (geographical area under the legal control of a country)',
  'stop, stand, stopping place, stopover; parking lot, car park',
  'balance, scales',
  'incoming; brought in, introduced, initiated; foreign, adventitious; added, adopted; loan, foreign',
  'cover, anything that covers: covering, lid, blanket, etc.',
  'to stir; to shuffle; to rearrange in a disorderly manner',
  'rolling pin',
  'jaundice; chlorosis, greensickness; blight, mildew',
  'station (bus, railway, radio etc.)',
  'hill',
  'bared (of teeth); protruding (of teeth); protruding (of eyes)',
  'letter, character; consonant',
  'version, edition; revision, translation; procession, expedition; cost, expense; tumor/tumour, blain, eruption; utterance, pronunciation',
  'caliph',
  'noisy, clamorous, vociferous',
  'game; match',
  'pistachio; peanut',
  'date fruit and tree',
  'sensitive, endowed with senses; allergic (having an allergy); allergic (of or pertaining to an allergy)',
  'wise, smart',
  'to tap, pat, hit gently; to drizzle, drip one drop at a time',
  'tree',
  'update; renewal, renovation, restoration; revival, modernisation, innovation; consecration, dedication',
  'round',
  'object, item, article; object',
  'viper',
  'arrival; access',
  'female',
  'lock, bolt',
  'meditation; syllable, vowel; vocalization of a word; method of learning to read through syllables',
  'plural of ܒܪܘܿܢܵܐ (brōnā): sons',
  'absolute plural form of ܢܲܗܪܵܐ (nahrā, “river”)',
  'sour, unripe grape',
  'Autumn month; Tishrei: The first month of the Jewish calendar (of twenty-nine days), following Elul and preceding Cheshvan.',
  'young, youthful, juvenile',
  'second',
  'linking, joining together; articulation, joint, knuckle; seam; solder, welding; particle, connecting word',
  'kangaroo (a member of the Macropodidae family of large marsupials with strong hind legs for hopping, native to Australia)',
  'congratulations (to a man) (used to express praise and approval, expressing approbation); may it be blessed (used to congratulate or compliment someone upon receiving something new, an award, etc.)',
  'lie',
  'to ululate (to emit a series of high-pitched noises, done by Middle Eastern women at times of celebration)',
  'to knock',
  'glove',
  'breakfast (first meal of the day, eaten in the morning); meal, repast; feast, banquet',
  'female equivalent of ܐܲܪܝܵܐ (aryā) lioness',
  'idle, inactive; invalid, null, void; useless, worthless, valueless',
  'spoon (scooped utensil for eating or serving); spoonful, small teaspoon; probe (instrument used to explore wounds or organs)',
  'blue; light blue, sky blue',
  'tight, fastened; girded, strong, valiant, manly',
  'a male given name, equivalent to English Anthony, Informal form of ܐܲܢܛܘܼܢܝܘܿܣ (anṭunyōs).',
  'oven',
  'Tradition practiced by Assyrians (especially of Syria) to motivate children to fast during the Nativity Fast & Lent through the use of frightening costumes and candy.; Halloween',
  'normal, regular, ordinary, usual',
  'whole, entire, overall; public, general',
  'in the morning; ante meridiem, a.m.',
  'ventilation hatch of attic, hatch by which grain is poured into the attic granary; round opening on top of the ceiling',
  'anger, wrath, fury; divine punishment',
  'Muhammad',
  'Mauritius (a country and island in the Indian Ocean, east of Africa).',
  'necessary, obligatory, compulsory; important, significant; essential, substantial; compelled',
  'to cut up, to chop up; to cut up',
  'funeral',
  'pardon, forgiveness',
  'that which is due or right; what must be done; appropriateness, decorum, necessity; obligation, duty, office; task, assignment, homework; honor, integrity, dignity, grace',
  'business, businesslike',
  'to scratch, claw',
  'smith',
  'useful, practical, handy',
  'public; prevalent, universal; general, common, ordinary',
  'backbone, spine',
  'Jamaica (an island and country in the Caribbean)',
  'old, elderly, aged',
  'graduate, alumnus',
  'egg; ovum, egg cell; testicles, balls',
  'clutch',
  'rubbing, chafing, scratching; friction',
  'to surround, wrap around, enfold, enclose',
  'to trip, stumble',
  'sharp, pointed; high-pitched, shrill; acute, of an angle less than 90°; harsh, critical; hot, strong, spicy',
  'a male given name from Biblical Hebrew, equivalent to English John; a surname transferred from the given name; Any of the New Testament figures named John; The Gospel of John: a book of the New Testament of the Bible. Traditionally the fourth of the four gospels.; One of the books in the New Testament of the Bible, the epistles of John (1 John, 2 John and 3 John).',
  'to wet one’s feet; to paddle; to stall, take long, procrastinate',
  'enthusiasm, passion; contentment, satisfaction',
  'last, final, ultimate; recent',
  'repetition, iteration, recitation; citing, recounting; frequency',
  'dual of ܡܐܵܐ (mˀā), two hundred',
  'oak; acorn',
  'litre; libra, (Roman) pound',
  'teacher, instructor',
  'nice to meet you; pleasure; thank you',
  'to develop spots, bubbles, blisters, pustules, etc.',
  'vein, artery, nerve; root, stem, trunk',
  'blade',
  'raisin',
  'to become dirty, foul; to make dirty, foul',
  'sour, unripe, acidic',
  'dark-skinned; brown, tan, tawny, deep yellow',
  'university student',
  'artificial, synthetic, man-made',
  'to be crazy, to act foolishly; to make crazy, to drive crazy (positively or negatively)',
  'to beseech, supplicate, implore, call upon; to beg, plea',
  'jaw; chewer; one who chews',
  'to colonize',
  'true, real, factual; firm, solid',
  'wife’s sister’s husband',
  'Qazvin (a province of Iran); Qazvin (the capital city of Qazvin Province, Iran)',
  'Lesotho (a country in Africa)',
  'turned off, switched off, off, extinguished, unlit',
  'green color',
  'Alternative form of ܛܸܫܝܵܐ (ṭišyā, “hidden, hiding”)',
  'railing, hand railing, guard railing; parapet, balustrade; bench, seat, footstool',
  'easy, plain; short in sound, syllable, or vowel',
  'cutter; paragraph, verse, aphorism; declarative, enunciative sentence; period, full stop; an episode (of a TV programme)',
  'tight',
  'pig, swine, hog',
  'melon (large round fruit with sweet pulpy flesh and many seeds); watermelon',
  'edible seed; fruit stone',
  'watermelon (large fruit with smooth green skin, red pulp, and watery juice)',
  'scorpion',
  'ice (water in its frozen state, a brittle transparent crystalline solid); piece of ice',
  'marine, maritime (of or pertaining to the sea); nautical, naval',
  'Russian (of, or pertaining to Russia)',
  'careful',
  'yellow; orange',
  'sweet agent; candy, sweet',
  'medal',
  'The two months December and January',
  'Jemima (oldest of the three daughters of Job); a female given name from Hebrew, equivalent to English Jemima',
  'turned on, switched on, on, lit, burning',
  'ladder (frame used for ascent and descent, consisting of two side pieces and fastened rungs acting as stairs); scale',
  'nearness, proximity, vicinity',
  'forgetting, neglecting; forgetfulness; oblivion',
  'then, afterward, subsequently; used to indicate order of time, being later occurring, or even in lesser degree of impressiveness',
  'number, numeral, figure; numbering, enumeration, census; number; sum',
  'Genesis',
  'Gemini (constellation and zodiac sign)',
  'to picnic, to have a picnic; to promenade; to have fun, sport, amuse oneself; to recreate; to unwind, relax',
  'cooking; cooked food; cuisine, cookery; style of cooking; type of cooked food',
  'laundry (that which needs to be, is being, or has been laundered).',
  'thin, delicate, slim; skinny, emaciated, thin; tender (of the voice)',
  'abode, dwelling; sojourning; habitat',
  'a male given name; a surname',
  'female equivalent of ܐܲܟܵܪܵܐ (akkārā): farmer',
  'cool, breezy',
  'gasoline, petrol, motor fuel',
  'vagina (passage leading from the opening of the vulva to the cervix of the uterus for copulation and childbirth in female mammals)',
  'keeper, one who keeps; guard, protector, one who protects',
  'bucket, pail (especially metallic or plastic, with one handle)',
  'vulva (the external female sexual organ)',
  'Beirut (the capital and largest city of Lebanon)',
  'garage (building or section of building to store a car, tools etc.); hangar',
  'a film, movie',
  'jewel, precious stone, gem, gemstone (especially an emerald)',
  'heir, inheritor',
  'swimming pool (pool of water used for swimming, usually one which has been artificially constructed)',
  'wish, desire, hope; regards, good wishes; kind regards, yours sincerely polite closing of a letter or e-mail communication',
  'figure, stature; fathom, height of a man; mound of snow',
  'eyebrow',
  'sudra, scarf, turban; cloth, towel, shroud, linen, handkerchief',
  'safe, riskless, harmless',
  'to trample, tread on/over, run over; to stamp, pound, crush, beat',
  'pacific, peaceful, tranquil, calm, serene',
  'father-in-law (father of one’s spouse)',
  'flute, zurna, pipe; tibia',
  'certain, sure, definite; stable, secure, firm; standing, upright',
  'in the beginning',
  'cute, sweet, charming; desirable, precious',
  'Mongolia (a country in East Asia)',
  'sock; stocking',
  'Zambia (a country in Africa)',
  'brake (device used to slow or stop a vehicle or machine)',
  'Rome (the capital city of Italy and ancient capital of the Roman Empire)',
  'channel, canal; TV channel, radio channel',
  'to steer',
  'prism (polyhedron with parallel ends of the same size and shape); prism (block used to split or reflect light)',
  'doll, puppet, marionette (toy in the form of a human, usually of a baby or girl); darling, sweetheart, babycakes (term of affection for a baby or young woman)',
  'ill, sick (in poor health)',
  'shouting, calling loudly; loudspeaker; telephone',
  'to conclude, suppose, reckon; to calculate',
  'composer, compiler, author; builder, founder; legislator',
  'disabled, handicapped person',
  'celestial body (especially star)',
  'instance of singing; manner of singing; song',
  'leftist; left-hander',
  'rough (in texture)',
  'expert, specialist; consultant',
  'female dog, hound, bitch; bitch a term of abuse for a woman; pincers, tongs, tweezers',
  'thought',
  'to speak, talk',
  'glove compartment; drawer, storage unit',
  'bomb',
  'fit, in shape, physically active; agile, nimble',
  'Semiramis or Shammuramat of Assyria, semi-mythical Assyrian queen; a female given name, equivalent to English Semiramis or Shammuramat',
  'asleep, sleeping; unintelligent, dumb, slow',
  'building, edifice; apartment building, residential building',
  'cloth for covering or wrapping bread or other food',
  'certain, sure, definite; precise, exact',
  'Bartella (an Assyrian town in northern Iraq located in the Nineveh Plains)',
  'librarian, library-keeper (keeper or manager of a library)',
  'swept; gathered, collected',
  'ascent; ascension, assumption',
  'ionization',
  'shy, bashful, easily embarrassed; modest',
  'to whip',
  'The third month of the Assyrian calendar; June (sixth month of the Gregorian calendar of thirty days)',
  'smart, intelligent; sensible, reasonable; well-behaved, well-mannered',
  'lowest part, bottom; underworld',
  'brick (hardened block used for building); adobe (unburnt brick dried in the sun)',
  'barley; barleycorn (unit of measure); kind of eye disease',
  'goatskin or sheepskin bag used to store meat or grain',
  'famous, well-known, renowned; distinct, explicit',
  'verbal noun of ܟܵܬ݂ܹܒ݂ (kāṯēḇ); handwriting; autograph, signature; writings',
  'clump, block, cluster; mass; bloc',
  'Chile (a country in South America)',
  'person from the region of Dasen; Yazidi (member of a Kurmanji-speaking people of northern Iraq, whose ethnic religion is Yazidism)',
  'bitter (in taste); deeply or painfully sad',
  'moving, mobile, movable; walking; marked with a vowel',
  'parallel',
  'complicated, complex; assembled, installed; made of, composed of, consists of',
  'a female given name',
  'to prefer, favor',
  'parachute (device designed to control the fall of an object or person)',
  'media',
  'knife, dagger',
  'belt, girdle, waistband, sash, strap',
  "you, ye, y'all, you guys; you yourselves",
  'revelation; appearance, apparition, manifestation',
  'victory, triumph; acquittal, innocence, justification',
  'to be/become sunny',
  'cracker, wafer',
  'fourth, quarter (¼)',
  'fricative',
  'to smile',
  'key (device used to open and close a lock); key (button on a typewriter or computer keyboard); key, clef (part of a piano or musical keyboard)',
  'perfume, fragrance, scent, aroma',
  'technology, tools, instruments, apparatus; creation, manufacture, construction, handiwork; repairing, restoration',
  'heap, pile, sheaf, stock',
  'Samoan (of, from, or pertaining to Samoa)',
  'demoniac, possessed person; crazy person; foolish person',
  'catastrophe, disaster, tragedy; calamity, affliction, misfortune, disaster',
  'pleasant',
  'long (having a great distance from one end to the other); long (of great duration); Used in the construct state, with a noun complement.; tall',
  'result, outcome, effect; total',
  'straight, direct; correct, right; even, regular; honest, righteous',
  'whip',
  'flight (trip made by an aircraft, particularly one between two cities or countries, which is often planned or reserved in advance.)',
  'exchange, replacement; compensation; interchangeability (e.g. of letters); apposition',
  'south (compass point, 180° directed toward the South Pole)',
  'studio, atelier',
  'trip, tour, excursion; travel, journey',
  'existing, being; to exist, be',
  'tandoor; cylindrical clay oven used especially to make bread',
  'pus, purulence; mucus, snot, runny nose',
  'instead of, in lieu of, in place of',
  'in the evening; post meridiem, p.m.',
  'law, canon; canon, hymn, chant; penalty, sanction, canonical fine; statute, precept, ordinance, tax; clerical order; classification of forms; type of verbal inflections',
  'wardrobe, closet (piece of furniture for storing clothes); cloakroom, locker; ark, box, chest, strongbox; coffin, sarcophagus',
  'to blush',
  'benediction; blessing; part of the wedding ritual where the groom and bride are blessed and married in a church by a priest.',
  'a male given name',
  'Mandaean, Sabian',
  'to climb; to be agitated, disquieted, shaken',
  'wallet, purse; portfolio',
  'to shove, push roughly or forcefully',
  'frying, scorching; slight, scorn, insult; outrage; challenge, contention, combat',
  'navel, belly button, umbilicus',
  'Medina (a city in Saudi Arabia)',
  'crutch',
  'to examine, test, review',
  'to legalize, make lawful, permit; to prescribe, dictate',
  'to screech, scream, shriek',
  'a surname originating as an occupation',
  'mountainous; of or relating to Tur Abdin',
  'hostage',
  'behind, backward',
  'interest, something one finds interesting; matter, issue; business',
  'clear, distinct, plain,; prominent, evident, apparent; obvious, certain; eloquent, fluent (of speech)',
  'open, unlocked; followed by the vowel sign pṯāḥā',
  'show, spectacle; exhibition',
  'forceps; tongs, lamp tongs; tweezers, snuffers',
  'silk',
  'angry, furious, enraged, vexed',
  'fog, mist, cloud; gloom, darkness',
  'vial (glass vessel or bottle, especially a small tube-shaped bottle used to store medicine, perfume or other chemicals)',
  'Karemlesh (an Assyrian town in northern Iraq)',
  'thousandth (1000th)',
  'tens (of something); indicates an indefinite number approximately between ten and a hundred',
  'earring (piece of jewelry worn on the ear); nose ring (ring worn as jewellery on the nose)',
  'high, raised; loud',
  'interchange, highway junction where streams of traffic do not intersect; connection between two or more lines, services or modes of transport',
  'player (person who plays or is playing a given game or sport); actor (person who performs in a theatrical play or film, or on television)',
  'A river in West Africa.; Gambia (a country in Africa)',
  'Georgia (a country, in the South Caucasus region of Europe and Asia, on the coast of the Black Sea)',
  'available, readily obtainable; ready, prepared, standing by, willing',
  'a surname',
  'mill',
  'fuchsia-related; magenta, fuchsia-colored',
  'to disgust, revolt, nauseate; to be disgusted, to feel disgust',
  'strawberry (sweet soft red fruit with a seed-studded surface)',
  'skilled, talented, diligent; smart, clever, bright; successful, prosperous; hardworking',
  'gleaning, gathering; selection, assortment, collection, extract; highlight; video clip, clip',
  'Alternative form of ܨܝܼܢܵܝܵܐ (ṣīnāyā, “Chinese”)',
  'spy; scout, searcher, explorer',
  'The sixth month of the Assyrian calendar; September (ninth month of the Gregorian calendar of thirty days); Elul (twelfth month of the Jewish calendar of twenty-nine days)',
  'Assyrian, Syriac',
  'dizziness',
  'email',
  'female equivalent of ܓܲܡܠܵܐ (gamlā, “camel, dromadery”)',
  'Malta (A European island and country in the Mediterranean Sea; official name: Republic of Malta)',
  'hungry (affected by hunger; desirous of food)',
  'third (⅓)',
  'to be/become fevered, to have a fever; to be/become extremely hot',
  'honest, sincere, truthful, telling the truth; moral, virtuous, ethical; only, unique, excellent',
  'to examine, search, look into, scrutinize; to go through, look through, read through',
  'to germinate; to inflect, decline, conjugate',
  'representative, delegate; protector, patron, overseer, officer, official',
  'past participle of ܣܵܒ݂ܹܪ (sāḇēr); anticipated, expected; may, might, likely to',
  'organization',
  'skinny, tight-fitting, skintight (of clothing); compact',
  'nearby, neighboring, adjacent; neighborly; obliging, helpful, amicable',
  'brown (colour like that of a tan, chocolate or coffee)',
  'smelly, foul-smelling, stinky',
  'pink (colour)',
  'Chinese (person from China or of Chinese descent); Asian, East Asian (person with Asian ancestry, specifically Northeast or Southeast Asia)',
  'prototype (an original object or form which is a basis for other objects); design, plan, drawing, pattern',
  'verily, truly, indeed, in fact',
  'singular (form of a word that refers to only one person or thing)',
  'female equivalent of ܫܵܩܝܵܐ (šāqyā, “cupbearer”); stream, channel, canal; TV channel, radio channel',
  'ruler (measuring or drawing device)',
  'necessary, indispensable, unavoidable, essential, inevitable, inescapable, requisite; binding, obligatory, incumbent, imperative; proper, adequate, fair',
  'holiday; appointment, date, meeting; rendez-vous; schedule, visiting time, opening time, business hours',
  'to roll',
  'recording (reproduction of sound, video, etc. stored in a permanent medium); record (disc, usually made from vinyl, on which sound is recorded and may be replayed on a phonograph)',
  "alone, sole; by one's self",
  'opening; explanation; sceptre',
  'to cure, remedy, heal, restore',
  'Nineveh (the ancient capital of Assyria); Nineveh as its original entirety, ie. Mosul and Nineveh; a female given name',
  'mouthful, bite (of food)',
  'emotional',
  'warning; announcement, notice; alarm',
  'a type of flat bread',
  'seventh (⅐)',
  'pole',
  'to hope (with ܕ- (d-)); to hope for (with ܥܲܠ (ˁal))',
  'engine, motor; machine',
  "granddaughter (daughter of someone's child)",
  'clod, lump',
  'lunch (light meal usually eaten around midday, notably when not as main meal of the day); noon, early afternoon',
  'patient (a person receiving or registered to receive medical treatment); ill person, sick person',
  'Egyptian (of, from, or pertaining to Egypt, the Egyptian culture, the Egyptian people or Egyptian language)',
  'simple, clear, obvious, straightforward; common, plain, ordinary; erect, straight; pure, uncompounded, not blended with anything else; extended',
  'to delay; to be late; to remain behind',
  'Alqosh (an Assyrian town in northern Iraq)',
  'extreme, far, intense, abnormal',
  'around',
  'bribe; blood money; forced contribution',
  'Korea (two countries in East Asia, North Korea and South Korea); South Korea (a country in East Asia)',
  'cheese',
  'recent, new, fresh; primary, first, initial',
  'free of charge, gratis',
  'week, period of seven days; sabbatical cycle; liturgical season divided into seven weeks',
  'decade (period of ten years); group of ten; ten-weight',
  'stork',
  'noble, aristocratic, refined, honourable; excellent, eminent, distinguished; well-behaved, well-mannered, polite',
  'debit',
  'idea, notion, thought; opinion, view, point of view',
  'gall bladder',
  'cooler (anything which cools); water cooler (dispenser of cooled drinking water)',
  'animal, beast',
  'ninth (⅑)',
  'words; sentence, paragraph, saying; phrase, clause; line, verse, versicle; foot, hemistich',
  'old, antique; ancient, archaic',
  'minister, cabinet minister; vizier, vicegerent',
  'to damage, injure, harm, ruin',
  'eggplant, aubergine',
  'helm, rudder of a ship; steering wheel of a car',
  'desire, wills; favor/favour, regard, goodwill; pleasure, delight',
  'past participle of ܝܵܗ݇ܒ݂ܹܠ (yāḇēl)',
  'colorful',
  'asleep, sleeping',
  'red; ruddy',
  'heavy; burdensome, challenging; serious; slow, slow-moving, sluggish; stupid, slow, thick; pregnant',
  'Rwanda (a country in Africa)',
  'busy, occupied',
  'to stutter, stammer; to mix, confuse; to disturb',
  'place, location, position; station, post, office; book passage; locality, region, quarter, direction; place, stead, lieu',
  'metre/meter',
  'wait a moment, hold on',
  'million (1,000,000; 10⁶)',
  'deprived, disadvantaged; free, empty, without; lacking, absent',
  'beginning, origin; opening, introduction, preface; exordium, kind of psalm',
  'resembling, similar, alike, analogous',
  'at least',
  'siege, military, blockade',
  'A dish made from buttermilk and grains',
  'separated, distinct, clear, different; select, chosen, set apart; special, outstanding, excelling, amazing; exclusive, private',
  'aerial, air-',
  'cork, bottle cap',
  'four (4)',
  'to agree',
  'cross, crucifixion, crucifix',
  'strong one; dear, darling',
  'to realize, become aware of',
  'choice, selection; collection, extract; winnowing, cleaning of grain; election',
  'guy, youth, young man; man, male, husband',
  'to vibrate',
  'to fog, fog up',
  'thanks to',
  'question, inquiry, query; debate, case, topic; interrogation, cross examination, questioning by torture; interrogative statement',
  'a male given name',
  'change, small change, loose change',
  'beautiful, attractive, sexy',
  'to slap, slam, smack',
  'bond, tie, chain; relationship; oath, promise; obligation; link, connection; weblink, hyperlink, link',
  'to masturbate',
  'Latvia (a country in northeastern Europe)',
  'accent (distinctive manner of pronouncing a language)',
  'Vietnam (a country in Southeast Asia)',
  'asparagus (Asparagus gen. et spp.)',
  'incorrect, wrong, erroneous, mistaken',
  'immediate, instant; prompt, instantaneous, without delay',
  'surname, family name, last name; kunya, epithet, cognomen; title, appellation; nominative case',
  'hollow',
  'space; firmament',
  'supposedly, as if',
  'single hair; whisker (long, projecting hairs growing at the sides of the mouth of a cat, or other animal)',
  'excess, remainder, rest, surplus, leavings; plenty, fullness, superfluity',
  'precise, accurate, exact; fine, thin; delicate, fragile, frail; little, small, tiny, minute',
  'intermission, pause, break; video clip, short film',
  'feminine of ܣܘܼܣܹܐ (sūsē): horse mare',
  'temple; shrine, holy place; nave, church; physique, frame; palace',
  'dimension',
  'edamame',
  'human being, person',
  'one another, each other; likewise, same to you',
  'to bleed',
  'Ethiopian (of, from, or pertaining to Ethiopia, the Ethiopian culture or people); Cushite, Kushite (of, from, or pertaining to Cush in ancient northeastern Africa); Cushitic (pertaining to the subfamily of the Afroasiatic family of languages)',
  'to understand, comprehend',
  'active, operative, in action, working, in force',
  'violent, oppressive; unjust, iniquitous',
  'Algeria (a country in north Africa; official name: People’s Democratic Republic of Algeria); Algiers (the capital city of Algeria)',
  'instance of marrying; manner of marrying; marriage',
  'east, orient',
  'spring (season when plants bloom and dormant animals spring to life); square tabor, tambourine, timbrel',
  'addicted; obsessed',
  'dining table, table board; leather sheet, eating mat, place mat, tablecloth',
  'hotel (establishment that provides accommodation and other services for paying guests)',
  'to be/become inflamed',
  'confident, trusting; sure, certain; dependent on, reliant on',
  'west, occident (the direction of the setting sun at an equinox)',
  'small, little; young (for a person)',
  'Nazarene (person who resides in or is from Nazareth); Christian (follower of Jesus of Nazareth)',
  'Cancer (constellation and zodiac sign)',
  'the river Nile',
  'external corner, edge; angle; point of view',
  'to scribble, scrawl, scratch, doodle',
  'a female given name',
  'koala',
  'to be/become excessive, extravagant, immoderate; to spread rumors, propagate, circulate; to shower with blessings or gifts, to bestow liberally',
  'spring, fount, fountain; source, origin, root; resources, assets',
  'prisoner; captive',
  'to wave (to move back and forth repeatedly and somewhat loosely); to wave (to cause to move back and forth repeatedly); to waft, flutter, flap wings; to palpitate (of the eyes)',
  'Persian (of, from, or pertaining to Persia, the Persian culture or Persian people)',
  'document (original or official paper); document (a file that contains text); ratification',
  'Gabriel (archangel associated with carrying messages from God); a male given name from Hebrew, equivalent to English Gabriel; a surname transferred from the given name',
  'Madrid (the capital city of Spain)',
  'portable bed (often situated on roofs or in fields)',
  'to rinse',
  'again, afresh, anew; re- used before both nouns and verbs to indicate repetition',
  'basin, washbasin, laver; sink for washing dishes; the handwashing basin in the Temple in Jerusalem',
  'politics',
  'fiery',
  'to be seen, visible; to seem, appear',
  'first (1st); primary, primitive',
  'to drag',
  'sixty (60)',
  'cleaning',
  'middle, central; average',
  'beautiful, pretty, handsome, fair, lovely',
  'Cairo (the capital city of Egypt)',
  'electric, electrical (of or pertaining to electricity, or powered by electricity)',
  'rake; carding-comb; stonecutter’s tool; instep',
  'fifty (50)',
  'of a person: humorous or amusing, either characteristically or on a particular occasion.',
  'bracelet, armlet',
  'complete, full, total, whole; honest, innocent; simple, naive, ignorant',
  'pepper, fruit of the capsicum; pepper, peppercorn spice made of berries',
  'departure, leaving; exodus, expulsion; result, outcome, effect',
  'burden, load, cargo; duty; luggage, baggage',
  'instance of seeing; an encounter between people; view, opinion',
  'dress (item of women’s clothing)',
  'distance, farness, remoteness; remove; degree of kinship of a different generation',
  'dusk (period of time at the end of day when sun is below the horizon but before full onset of night); afterglow',
  'world, this mortal life; life in this world; the weather conditions',
  'Ankawa (an Assyrian town in northern Iraq, now a suburb of Erbil)',
  'collection, gathering; festivity',
  'shoe (protective covering for the foot)',
  'furnace; oven',
  'preparation; presence, attendance',
  'person, individual; atom',
  'weak, feeble, frail; slim, fit, skinny, thin',
  'camomile',
  'Bahrain (a country in Asia)',
  'rooted; deep-seated, entrenched; indigenous, native',
  'together',
  'main, principal; superlative degree of ܛܵܒ݂ܵܐ (ṭāḇā); best',
  'with force, forcefully',
  'constitution; law, ordinance, rule (especially religious); custom, usage; nome, prefecture, province (especially of Ancient Egypt)',
  'a surname',
  'to search, go through another’s belongings in a violating manner',
  'Halmun (a historically Assyrian village in southeast Turkey)',
  'life as a period of time, length of life, lifespan, lifetime; age; church, house of worship',
  'to enslave, subjugate, enthrall',
  'eighty (80)',
  'oblique, slanting; followed by either of the two zlāmā vowel signs',
  'copyist, scribe',
  'help, aid, assistance, relief',
  'Peter (Biblical figure); a male given name from Greek',
  'inhabitant; sitter, one who sits; one about to sit, set to sit',
  'turned off, switched off, off',
  'fresh; pleasant',
  'aeroplane, airplane, plane (powered flying vehicle with fixed wings and a weight greater than that of the air it displaces)',
  'plot of land, lot; square, arena, plaza; playground; field, court',
  'drink, beverage',
  'a platonic or romantic term of endearment: beloved, sweetheart, dear, darling',
  'to subscribe, subscribe oneself; to be preordained by fate; to be meant to be; to be written, recorded, enrolled',
  'to oppose, be against; to disagree, contradict',
  'done, made, created; made of [with ܡ̣ܢ (min)] or',
  'maybe, perhaps',
  'mixture, compound, preparation; medicament, cream, ointment, salve, unguent',
  'street (road in a village or town usually with sidewalks, pavements and buildings)',
  'hot (relating to having or giving off a high temperature); hot, spicy; horny, excited, passionate, randy,; enthusiastic, willing, up for',
  'paunchy person; fat, overweight person; gluttonous person',
  'a female given name, equivalent to English Jasmine',
  'to signify, symbolize',
  'to concentrate; to focus, concentrate',
  'Bathsheba (in the Old Testament, the wife of Uriah and later of David); a female given name',
  'scan',
  'female equivalent of ܐܵܣܝܵܐ (āsyā, “medical doctor, healer, physician, nurse”)',
  'to drip copiously, trickle; to make, allow to drip something copiously dripping, trickle; to nod the head when dozing to sleep',
  'previous, preceding, prior, old (of an earlier time)',
  'pistol, revolver, handgun',
  'different',
  'to smoke, emit smoke; to smoke a cigarette, hookah, etc.; to smoke food (preserve or prepare food for consumption by treating with smoke)',
  'safe, secure, sound; healthy, well, prosperous; free',
  'instance of drinking; a drink; manner of drinking; drink, beverage; social gathering',
  'dual of ܐܲܠܦܵܐ (alpā), two thousand',
  'a video (short film clip, with or without audio)',
  'skirt (article of clothing that hangs from the waist and covers the lower part of the body)',
  'today',
  'flat, level',
  'furnishing, a piece of furniture; furniture, equipment',
  'Guyana (a country in South America)',
  'sofa, couch, settee',
  'feminine of ܕܲܪܵܓ݂ܵܐ (darāḡā, “cyclist”); bicycle, bike',
  'coming',
  'shallow, superficial',
  'word (unit of language); statement, saying, utterance',
  'right, appropriate, fitting, suitable',
  'conversational; colloquial, vernacular',
  'dark',
  'false, fake, untrue',
  'Congo (a large river in Africa); Congo (two countries in Africa, Democratic Republic of the Congo and Republic of the Congo)',
  'Norway (a country in Europe; official name: Kingdom of Norway)',
  'Czech (person from Czech or of Czech descent)',
  'to complete, finish, fill out',
  'tenth (⅒)',
  'body; solid, substance, matter; corpus, text; whole; collection, ensemble, group; organization, community, body',
  'cage, birdcage, pen, coop; checker, checkbox; box or basket, particularly if it is similar in mould to a cage',
  'remaining, left,; surviving, permanent, lasting, abiding; eternal, everlasting, relentless',
  'causing death, deadly, mortal, lethal',
  'crowded; followed by the vowel sign ḥḇāṣā',
  'meaning, definition, sense',
  'angry, furious, enraged, vexed',
  'great-grandchild, great-great-grandchild',
  'balloon (inflatable and buoyant object; child’s toy); hot-air balloon (inflatable object to transport people through the air)',
  'deliberation; weighing the pros and cons; conduct, behaviour, attitude',
  'sticky, adhesive',
  "you, ye, y'all; you yourselves",
  'Paul (the Apostle); a male given name from Latin, equivalent to English Paul; a surname transferred from the given name',
  'viewer, spectator',
  'famous person, celebrity (person who has a high degree of recognition)',
  'plateau; tray',
  'to design',
  'little brother; brother, bro, bruv (male sibling or used to address a male)',
  'humid, moist, damp; wet; fresh and tender',
  'sip, gulp',
  'far, distant, remote (in space or time)',
  'to translate (express the sense of words or text in another language); to interpret (express orally or in sign language the words of a person speaking a different language in real-time)',
  'inwardly',
  'to wrap, enfold, enclose',
  'shutter, blind (protective panels over windows); lock, bolt (fastener to prevent a door from being open)',
  'terminal',
  'zodiac sign, horoscope',
  'plan, project, technical drawing; plan, set of intended actions; plan, subscription to a service',
  'giraffe',
  'Germawa (an Assyrian town in northern Iraq, located in the Nineveh Plains)',
  'clear out, evacuation; retreat, withdrawal',
  'to sizzle (to make the sound of water boiling on a hot surface); to sizzle (to cook in a manner which causes that sound)',
  'to retire (to stop working on a permanent basis, usually because of old age or illness)',
  'to share, participate, take part; to associate; to partner, to join, to merge',
  'to chatter, prattle',
  'eleven (11)',
  'future (the time ahead; those moments yet to be experienced)',
  'distress, stress, anxiety, discomfort',
  'during, within, in the course of; for; for the last, over the past',
  'wet, moist, damp',
  'lacking, needful, needing, in need; to need (with ܠ- (l-) or ܥܲܠ (ˁal))',
  'empty; eaten alone (of food)',
  'etc., et cetera, and so on',
  'verbal noun of ܡܬܲܢܹܐ (mtannē, “to recite, recount”) recounting; word; command; promise',
  'to thunder; to make a loud, thundering sound',
  'awning, shade, alcove, canopy; booth, hut, shed, tent, tabernacle, sukkah; Sukkot; umbrella, parasol',
  'bee',
  'great, large, big, massive; many, numerous; rich',
  'to whisper',
  'to visit',
  'acceptance, receiving, admission; approval, tolerance; banquet of the Word',
  'a white person',
  'storm, gale, gust; tempest, hurricane',
  'horizon (line that appears to separate the Earth from the sky); skyline (silhouette against city or buildings); field of vision; distant land',
  'computer (programmable electronic device)',
  'past participle of ܟܵܟ̰ܹܚ (kāčēḥ)',
  'to hang, dangle; to swing from bar to bar, pole to pole, etc.',
  'small box, container, canister',
  'much, many, multiple, numerous, several; enough, too much, more than is needed',
  'uncircumcised, gentile, pagan',
  'to rattle; to rattle, startle, unsettle',
  'dial',
  'liberation; emancipation, manumission, enfranchisement; discharge, release',
  'pastry, baked good (baked food item made from flour and fat pastes such as pie crust)',
  'Arabia (a peninsula in the Middle East)',
  'experienced, having experience and skill in a subject',
  'seventy (70)',
  'avalanche',
  'student, pupil; learner',
  'blanket',
  'balance, scales; scale; shekel; beka; quantity of words or syllables',
  'slow (not quick in motion); careful, gradual; lazy, weak',
  'to confuse, to bewilder, to perplex; to throw off balance, to confound',
  'this year',
  'finger, toe, digit; finger (unit of measure equal to six barleycorns)',
  'avenue, boulevard, wide city street',
  'safe, saved, protected',
  'Switzerland (a country in Europe)',
  'machine, device, engine',
  'liquid (existing in the physical state of a liquid)',
  'dusky, swarthy; simple, ordinary, regular',
  'strange, weird, odd, peculiar; atypical, abnormal, unusual, eccentric; irregular, inconsistent, erratic; irregular, heteroclite; queer, homosexual, gay',
  'engaged, betrothed',
  'parliament (the legislative body in a parliamentary political system)',
  'childish, infantile, immature, juvenile',
  'warm',
  'dawn (first appearance of light in the sky before sunrise)',
  'tsunami, tidal wave (large and destructive ocean wave)',
  'instance of separating, manner of separating; a separation; intersection, crossroads',
  'bay, gulf (a body of water, extending from the sea, that is mostly surrounded by land); entrance, introduction, admission; arrival, entering',
  'climate (long term atmospheric conditions); The context in general of a particular political, moral, etc., situation.',
  'Brazil (a country in South America)',
  'valley; ravine, gorge',
  'salty, saline',
  'which; whichever (The/Any ... that)',
  'dry, arid (not wet; lacking moisture or humidity)',
  'gray/grey',
  'to dig intensely, to ravage a place through digging',
  'Alternative form of ܦܲܠܝܼܛܵܐ (palīṭā, “planet”)',
  'Mizraim (son of Ham and grandson of Noah)',
  'eloquent, loquacious, voluble; clear and distinct in speech; fluent',
  'chicken, hen',
  'pickaxe',
  'to tremble',
  'married',
  'toy',
  'quilt, comforter, duvet',
  'north (compass point, 0° directed toward the North Pole)',
  'emergency',
  'girl, female child; woman as either a compliment or an insult',
  'last year',
  'quickly, swiftly',
  'small bag; plastic bag, shopping bag; garbage bag',
  'pizza',
  'to mail, post',
  'dark in colour',
  'to cause, reason',
  'invader, occupier; nightmare, incubus',
  'switch (device to turn electric current on and off); key',
  'serious; strong, mighty',
  'terrifying, frightening, awe-inspiring',
  'constant, continuous, continual; eternal, endless, enduring; reliable, dependable, trustworthy, believable; authentic, credible',
  'to encounter, meet by chance, bump into, run into',
  'Barbara, an early Christian martyr and saint; a female given name from Greek',
  'headscarf; Yalikhta, a traditional Assyrian crocheted handkerchief sewed with beads used in dancing',
  'deep; profound',
  'born, native',
  'length; longitude',
  'migrant, immigrant; settler, sojourner',
  'eight (8)',
  'boiling',
  'more, most; extra, additional; excessive',
  'to become foul, dirty; to make foul, dirty',
  'a female given name from Latin, equivalent to English Julia or Julie',
  'teacup (small cup, normally sitting in a saucer as part of a tea set, commonly used for drinking tea); gill (a drink measure of one quarter of a pint, for spirits and wine)',
  'right direction; right hand; pledge, promise, oath, compact; ordination; arranging, ordering',
  'The next, coming, upcoming, following, subsequent; accessible, reasonable',
  'light, bright, semidark in color',
  'a male given name; a surname transferred from the given name',
  'milliard, billion (1,000,000,000; 10⁹)',
  'frying pan, pan (long-handled shallow pan used for frying food)',
  'nickname (familiar, invented given name)',
  'tomorrow',
  'twelve (12)',
  'fifth (⅕)',
  'disk; discus; tablet, pill',
  'bright, shining',
  'existent, present; real, actual',
  'to buzz, to ring; to telephone, call (another person on a phone)',
  'rust, aerugo, tarnish, verdigris, patina; tartar (on teeth), sordes; dirt, grime, filth; foulness; trash, rubbish; trash can',
  'swimmer (person who swims, especially one who participates in swimming competitions)',
  'bowl (vessel used to hold mix or present food)',
  'shop, store (establishment that sells goods)',
  'equipment, device, appliances, outfit, gear, rig; implement, utensil, appliance, contrivance, gadget; installation, apparatus; system, apparatus',
  'direction, orientation, heading (indication of the point toward which an object is moving)',
  "box, ark, chest; Noah's ark; Ark of the Covenant; casket, coffin, sarcophagus",
  'different, unlike, dissimilar, not the same as; various; insane, crazy, frantic, frenzied',
  'tunnel (underground or underwater passage)',
  'to hurry, rush, hasten; to quicken the pace, speed up, urge',
  'Hebrew (of or pertaining to Hebrew people or language)',
  'extremist, radical',
  'case, matter, business, affair; cause; possession, property, good; furnishing, a piece of furniture; furniture',
  'to make an enemy',
  'Isaac; a male given name from Hebrew',
  'coronation',
  'aid, assistance, rescue',
  'brush (tool of flexible bristles attached to a handle for cleaning, sweeping and arranging hair)',
  'speech, talk, discourse, diction; an act of speaking; a speech, oration',
  'to grumble, complain, murmur with discontent',
  'question; interrogation; cross examination; debate, case, topic; interrogative sentence',
  'ready, prepared; willing',
  'original, authentic',
  'point, dot; point, moment; aspect, facet; vertex; full stop, period; diacritical marks',
  'wing, fin; wing',
  'skilled, proficient, adept; efficient, effective, efficacious, good',
  'ninety (90)',
  'relative, kinsman, kin',
  'note; melody, tone, sound',
  'cheek; mastic gum, a kind of traditional Assyrian chewing gum made from the resin of the pistacia tree',
  'past participle of ܚܵܠܹܡ (ḥālēm, “to dream”); sound, whole, healthy; strong, vigorous, robust; thick',
  'verbal noun of ܚܲܕܸܬ (ḥaddit) renewal',
  'episode; scene',
  'only, just',
  'human, of or relating to human beings or humankind; humane',
  'value, worth',
  'copy; transcript; prescription, recipe',
  'baklava (popular sweet pastry found in many cuisines of the Middle East and the Balkans)',
  'husband’s brother',
  'hatred',
  'abominable, detestable; hideous, repulsive; awful, horrible, terrible, very bad',
  'circle; revolution, cycle, circuit; circuit, cycle, course, series; khudhra, breviary of East Syriac rites; Ellipsis of ܚܘܼܕܪܵܐ ܕܡܲܪܕܝܼܬܵܐ (ḥudrā d-mardītā, “roundabout”).',
  'Shaqlawa (a historically Assyrian town in northern Iraq)',
  'fingernail, toenail; claw, talon, hoof; hypopyon; onycha, onyx',
  'buyer, client, customer, patron',
  'edge of clothing',
  'amber',
  'shoemaker, cobbler (worker who makes or repairs shoes)',
  'to crease; to wrinkle',
  'hand-held fan',
  'to spurt, to squirt, to spray everywhere; to spurt, to squirt, to be sprayed everywhere',
  'black; dark, obscure, sunburnt; unlucky',
  'floor, storey, level',
  'fatherhood, paternity',
  'Sir, Mister (a title of respect for a man, preceding his surname)',
  'biased, prejudiced',
  'to change, alter, edit',
  'fountain, spring; well; source',
  'physics',
  'to flake, flake off',
  'structure, building',
  'ring',
  'thankfulness, gratitude',
  'wrestling, wrestling match; contest; striving, struggle; fight, combat; battle, war',
  'entry, log, datum, record; beginning, introduction, preface; induction, inauguration; dedication of a church; revenue, income; crop, produce',
  'car, automobile (passenger vehicle steered by a driver)',
  'rocket; missile',
  'other, another, one more, else; different, unusual',
  'lentil',
  'ahead; forward, onward',
  'addict',
  'to gush, pour, flow down with plashing',
  'to rumble',
  'Belgium (a country in Western Europe)',
  'letter, epistle; message',
  'cooked; ripe; baking, boiling hot',
  'to whiz, whir',
  'feminine of ܬܪܹܝܢ (trēn, “two”)',
  'arm',
  'use, utility; advantage; profit, gain',
  'written',
  'to misbehave, mess around',
  'few, some (an indeterminate small number)',
  'platform, pier, wharf, quay',
  'measure, measurement',
  'tasty (having a pleasant taste)',
  'shirt, T-shirt',
  'semaphore, signal (equipment used for visual signalling); traffic signal, traffic light (signalling device positioned at a road intersection); indicator, turn signal',
  'rich, wealthy, well off',
  'tobacco',
  'Alternative form of ܐܸܚܕ݂ܵܕ݂ܹܐ (iḥḏāḏē, “one another, each other”)',
  'twenty (20)',
  'dye, pigment; color',
  'burned, burnt (damaged or injured by fire or heat); damned, burnt (in Hell)',
  'on the contrary; the other way around',
  'dark, obscure, dim; deep in color; rich, highly saturated; hidden, secret, sinister',
  'cycle, circuit, period; course, learning program',
  'to believe, to accept as true or that one is telling the truth; to believe in; to approve, ratify',
  'past participle of ܙܵܓ݂ܹܪ (zāḡēr); prohibited, banned; closed, shut, sealed',
  "the Qur'an",
  'step- used to indicate that the person being identified is not a blood relative but is related through the marriage of a parent',
  'hut, shack, cottage, lodge, hutch, cabin; small storage chamber, closet, pantry',
  'individual small slope or incline of a hill',
  'table; surface where food is placed and eaten; (dining table, dining ground, etc.)',
  'stem',
  'restaurant (an eating establishment in which diners are served food)',
  'supermarket (large self-service store that sells groceries, medications, household goods and/or clothing)',
  'spear, lance, javelin; meteor, shooting star',
  'shopping trolley; cart, wagon, carriage; pram, baby buggy, pushchair, stroller',
  'pilot, aviator (controller of an aircraft)',
  'crossing, passage, traverse',
  'to drive an animal away, to shoo; to drive off a person as though an animal',
  'to meow; to persistently beg, plead',
  'seventeen (17)',
  'sixth (⅙)',
  'splendid, shining, luminous, glowing',
  'pomegranate (spherical fruit with a tough golden-orange outer skin and sweet red gelatinous flesh containing many seeds); grenade (small explosive device thrown by hand)',
  'annually, yearly, every year, each year',
  'sleeve (especially a long sleeve)',
  "wheel (especially of a carriage, well, or mill); potter's wheel; torture device; cycle, circuit, circular course, orbit; angels; celestial sphere; thistle stalks",
  'camera',
  'to roughen',
  'shoulder; side',
  'soup, broth, stew',
  'to push',
  'to ring, chime; to resonate; to rust, corrode, oxidize',
  'to crush, to bruise; to grind, to pulverize, to powder',
  'son (boy or man in relation to his parents); boy, child; endearing term for young boys or men who are younger; little son, sonny',
  'testament',
  'printer (operator of a printing press or the owner of a printing business); person who seals',
  'solicitous, desiring, eager; desirable, excellent, outstanding, amazing',
  'bachelor, unmarried man',
  'to ponder, contemplate, speculate; to worry, to be concerned (about something)',
  'office, bureau (room or building used for writing and non-manual work); office, bureau, ministry (major governmental division); desk (table for writing and reading)',
  'to torment, afflict, torture; to injure, wound, hurt, cause pain; to suffer, feel pain, feel agony',
  'alchemy; chemistry',
  'helium',
  'kitchen',
  'woman; wife',
  'less, fewer; sparse, rare; incomplete, lacking; insufficient, inadequate',
  'stall, kiosk, booth (small open-fronted shop) (in a market, food court, etc.); counter (in a pharmacy, etc.)',
  'karat, carat (measure for purity of gold, precious stones and pearls); a quarter of a shekel',
  'scorched rice in the bottom of a pot',
  'sad, unhappy, sorrowful (experiencing the emotion of unhappiness); sorry, regretful',
  'present; immediate, instant; circumstantial',
  'nineteen (19)',
]

export const PARTS_OF_SPEECH = [
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'adj',
  'noun',
  'adv',
  'verb',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'name',
  'noun',
  'adj',
  'verb',
  'adj',
  'noun',
  'noun',
  'name',
  'verb',
  'name',
  'noun',
  'noun',
  'noun',
  'noun',
  'name',
  'noun',
  'verb',
  'name',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'verb',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'name',
  'adj',
  'adj',
  'noun',
  'noun',
  'intj',
  'noun',
  'verb',
  'verb',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'adj',
  'adj',
  'name',
  'noun',
  'name',
  'adj',
  'adj',
  'adv',
  'noun',
  'noun',
  'name',
  'name',
  'adj',
  'verb',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'noun',
  'adj',
  'adj',
  'noun',
  'name',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'verb',
  'adj',
  'name',
  'verb',
  'noun',
  'adj',
  'noun',
  'num',
  'noun',
  'noun',
  'noun',
  'intj',
  'verb',
  'noun',
  'noun',
  'noun',
  'verb',
  'adj',
  'adj',
  'noun',
  'adj',
  'verb',
  'verb',
  'noun',
  'verb',
  'adj',
  'noun',
  'name',
  'name',
  'adj',
  'noun',
  'adj',
  'noun',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'noun',
  'noun',
  'name',
  'name',
  'adj',
  'noun',
  'noun',
  'noun',
  'adv',
  'noun',
  'name',
  'name',
  'verb',
  'noun',
  'noun',
  'adj',
  'noun',
  'name',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'name',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'adj',
  'noun',
  'noun',
  'adj',
  'adv',
  'adj',
  'name',
  'noun',
  'name',
  'noun',
  'name',
  'noun',
  'verb',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'adj',
  'name',
  'adj',
  'noun',
  'noun',
  'adj',
  'name',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'verb',
  'name',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'name',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'name',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'pron',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'adj',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'prep',
  'adv',
  'noun',
  'noun',
  'verb',
  'noun',
  'name',
  'noun',
  'verb',
  'noun',
  'verb',
  'noun',
  'noun',
  'name',
  'noun',
  'verb',
  'verb',
  'verb',
  'name',
  'adj',
  'noun',
  'adv',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'name',
  'adj',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'name',
  'name',
  'adj',
  'name',
  'noun',
  'adj',
  'verb',
  'noun',
  'adj',
  'noun',
  'adj',
  'noun',
  'name',
  'adj',
  'noun',
  'noun',
  'noun',
  'name',
  'adj',
  'noun',
  'verb',
  'adj',
  'verb',
  'verb',
  'noun',
  'adj',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adv',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'noun',
  'adv',
  'noun',
  'verb',
  'name',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'adv',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'verb',
  'name',
  'adj',
  'prep',
  'noun',
  'name',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'verb',
  'adj',
  'adj',
  'adj',
  'adj',
  'name',
  'adj',
  'verb',
  'noun',
  'noun',
  'intj',
  'num',
  'adj',
  'noun',
  'adj',
  'prep',
  'noun',
  'noun',
  'adj',
  'adj',
  'noun',
  'num',
  'verb',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'verb',
  'verb',
  'prep',
  'noun',
  'name',
  'noun',
  'adj',
  'verb',
  'noun',
  'verb',
  'name',
  'noun',
  'name',
  'noun',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'adv',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'pron',
  'verb',
  'adj',
  'verb',
  'adj',
  'adj',
  'name',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'verb',
  'adj',
  'noun',
  'adj',
  'noun',
  'name',
  'name',
  'noun',
  'verb',
  'name',
  'noun',
  'verb',
  'noun',
  'noun',
  'verb',
  'adj',
  'noun',
  'name',
  'name',
  'noun',
  'verb',
  'adv',
  'noun',
  'noun',
  'adj',
  'verb',
  'adj',
  'verb',
  'num',
  'noun',
  'adj',
  'adj',
  'name',
  'adj',
  'noun',
  'num',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'name',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'name',
  'adj',
  'adv',
  'adj',
  'adv',
  'noun',
  'name',
  'verb',
  'name',
  'noun',
  'verb',
  'num',
  'adj',
  'noun',
  'noun',
  'name',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'verb',
  'adj',
  'adv',
  'noun',
  'noun',
  'adj',
  'noun',
  'name',
  'verb',
  'verb',
  'name',
  'noun',
  'noun',
  'verb',
  'adj',
  'noun',
  'adj',
  'verb',
  'adj',
  'noun',
  'num',
  'noun',
  'noun',
  'adv',
  'adj',
  'noun',
  'name',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'name',
  'name',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'pron',
  'name',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'adj',
  'noun',
  'adj',
  'verb',
  'adv',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'name',
  'noun',
  'verb',
  'verb',
  'verb',
  'verb',
  'num',
  'noun',
  'noun',
  'prep',
  'adj',
  'adj',
  'adj',
  'adv',
  'noun',
  'verb',
  'noun',
  'noun',
  'adj',
  'verb',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'verb',
  'noun',
  'adj',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'name',
  'adj',
  'num',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'adv',
  'noun',
  'noun',
  'adj',
  'name',
  'noun',
  'adj',
  'adj',
  'adj',
  'adj',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'name',
  'noun',
  'adj',
  'pron',
  'adj',
  'noun',
  'verb',
  'noun',
  'name',
  'adj',
  'noun',
  'noun',
  'verb',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'adv',
  'adv',
  'noun',
  'noun',
  'verb',
  'adj',
  'verb',
  'noun',
  'noun',
  'adj',
  'adj',
  'adj',
  'verb',
  'name',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'num',
  'adj',
  'adj',
  'verb',
  'name',
  'noun',
  'noun',
  'adj',
  'adj',
  'name',
  'num',
  'noun',
  'noun',
  'adv',
  'num',
  'noun',
  'noun',
  'adj',
  'adj',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'adj',
  'noun',
  'noun',
  'verb',
  'name',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'adj',
  'adj',
  'noun',
  'noun',
  'adj',
  'num',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'adv',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'name',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'verb',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'verb',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'adv',
  'noun',
  'verb',
  'verb',
  'name',
  'noun',
  'adj',
  'verb',
  'num',
  'noun',
  'noun',
  'adj',
  'verb',
  'pron',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'adj',
  'noun',
  'pron',
  'num',
  'noun',
  'adj',
  'adv',
  'adj',
  'noun',
  'verb',
  'adj',
  'name',
  'adj',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'noun',
  'verb',
  'verb',
  'num',
  'noun',
  'adj',
  'noun',
  'adv',
  'noun',
  'noun',
  'noun',
  'verb',
  'noun',
  'noun',
  'verb',
  'verb',
  'verb',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'verb',
  'noun',
  'verb',
  'noun',
  'noun',
  'noun',
  'noun',
  'adj',
  'noun',
  'noun',
  'noun',
  'adj',
  'adj',
  'num',
]
