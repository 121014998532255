import { getGuessStatuses } from './statuses'
import { solutionIndex } from './words'
import { GAME_TITLE } from '../constants/strings'
import { MAX_CHALLENGES } from '../constants/settings'

export const shareStatus = (
  guesses: string[],
  lost: boolean,
  isHardMode: boolean
) => {
  navigator.clipboard.writeText(
    `${GAME_TITLE} ${solutionIndex} ${
      lost ? 'X' : guesses.length
    }/${MAX_CHALLENGES}${isHardMode ? '*' : ''}\n\n` +
      generateEmojiGrid(guesses)
  )
}

export const generateEmojiGrid = (guesses: string[]) => {
  return guesses
    .map((guess) => {
      const status = getGuessStatuses(guess)
      return (
        '\u202E' +
        guess
          .split('')
          .map((_, i) => {
            switch (status[i]) {
              case 'correct':
                return '🟩'
              case 'present':
                return '🟨'
              default:
                if (localStorage.getItem('theme') === 'dark') {
                  return '⬛'
                }
                return '⬜'
            }
          })
          .join('')
      )
    })
    .join('\n')
}
